import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import SettingsCellRenderer from '../settings-cell-renderer';
import { HighlightedBodyTextBold } from '../../../typograph/typograph-components';
import { Checkbox } from '../../../input';

const StickyCellWrapper = styled(Flex)`
    align-items: center;
`;
const StickyCellName = styled(HighlightedBodyTextBold)`
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;
export const CheckboxWrapper = styled.div`
    padding-right: ${({ theme }) => theme.spacing.default};

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        padding-right: ${({ theme }) => theme.spacing.large};
    }
`;
const StickyOverviewCellRenderer = ({ row, column, value, ...rest }) => {
    return (
        <StickyCellWrapper>
            {row.getToggleRowSelectedProps && (
                <CheckboxWrapper>
                    <Checkbox {...row.getToggleRowSelectedProps()} />
                </CheckboxWrapper>
            )}

            <StickyCellName>
                {column.getTextValue?.(row) || value || ''}
            </StickyCellName>
            {column.getOptions &&
                SettingsCellRenderer({
                    column,
                    row,
                    value,
                    title: column.getMobileOptionsMenuTitle?.(row),
                    ...rest,
                })}
        </StickyCellWrapper>
    );
};

export default StickyOverviewCellRenderer;
