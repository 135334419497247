import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputField as TextInput } from '../.';
import { ErrorWrapper } from '../../../../utils/hoc';

const Wrapper = styled.div`
    display: flex;
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon, {
    shouldForwardProp: prop => prop !== 'hasLabel',
})`
    position: absolute;
    right: 10px;
    top: ${({ hasLabel }) => (hasLabel ? '40px' : '8px')};
    font-size: 1.15rem;
    color: ${({ theme }) => theme.colors.textSecondary};
`;

const DatepickerInput = forwardRef(
    ({ value, onClick, dateFormat, helpText, label, ...rest }, inputRef) => {
        const placeholder = dateFormat.toUpperCase();
        return (
            <Wrapper>
                <div>
                    <ErrorWrapper {...rest} helpText={helpText}>
                        <TextInput
                            {...rest}
                            inputRef={inputRef}
                            type="text"
                            value={value}
                            autoComplete="off"
                            placeholder={placeholder}
                            onClick={onClick}
                            label={label}
                        />
                        <StyledFontAwesomeIcon
                            icon={faCalendarDays}
                            onClick={onClick}
                            hasLabel={!!label}
                        />
                    </ErrorWrapper>
                </div>
            </Wrapper>
        );
    }
);

DatepickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
    dateFormat: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    label: PropTypes.string,
};

export default DatepickerInput;
