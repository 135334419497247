import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { SideBarMenuItem } from './';

const MenuContainer = styled(Flex)`
    flex-direction: column;
    width: 240px;
    min-width: 240px;
    overflow-y: auto;
`;

const GroupDivider = styled.hr`
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colors.bgSecondary140};
    margin: 10px 16px;
`;

const hasActiveSubItem = (item, activePath) =>
    item.subItems.find(
        ({ path, subItems: thirdLevelItems = [] }) =>
            path === activePath ||
            thirdLevelItems.find(
                thirdLevelItem => thirdLevelItem.path === activePath
            )
    ) !== undefined;

const findExpandedItem = (items, activePath) =>
    items.find(item => hasActiveSubItem(item, activePath));

function SidebarMenu({ items = [], activePath, ...props }) {
    const [expandedItem, setExpandedItem] = useState(
        findExpandedItem(items.flat(), activePath)
    );
    const toggleExpanded = item => () => {
        setExpandedItem(item.label === expandedItem?.label ? null : item);
    };

    useEffect(() => {
        if (activePath && !findExpandedItem(items.flat(), activePath)) {
            setExpandedItem(null);
        }
    }, [activePath, items]);

    return (
        <MenuContainer {...props}>
            {items.map((groupItems, index) => (
                <Fragment key={index}>
                    {index > 0 && <GroupDivider />}
                    {groupItems.map(item => (
                        <SideBarMenuItem
                            key={item.label}
                            item={item}
                            activePath={activePath}
                            toggleExpanded={toggleExpanded(item)}
                            isExpanded={
                                expandedItem?.label === item.label ||
                                item.path === activePath
                            }
                        />
                    ))}
                </Fragment>
            ))}
        </MenuContainer>
    );
}

export default SidebarMenu;

SidebarMenu.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                path: PropTypes.string,
            })
        )
    ),
    activePath: PropTypes.string,
};
