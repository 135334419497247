import { ac } from '../../utils/redux-utils';
import providerServicesApi from '~/features/provider-services/api/provider-services-api';

export const FETCH_DEAL_WIZ_SERVICES_SUCCESS =
    'FETCH_DEAL_WIZ_SERVICES_SUCCESS';
export const SET_DEAL_WIZ_SELECTED_SERVICES = 'SET_DEAL_WIZ_SELECTED_SERVICES';
export const SET_DEAL_WIZ_OBJECT_TYPE_ID = 'SET_DEAL_WIZ_OBJECT_TYPE_ID';

function successFetchDealWizServices(wizDealServices) {
    return ac(FETCH_DEAL_WIZ_SERVICES_SUCCESS, wizDealServices);
}

export function fetchAvailableServices(organizationUnitId, objectTypeId) {
    return async dispatch => {
        try {
            const sortedServices =
                await providerServicesApi.fetchAvailableServices(
                    organizationUnitId,
                    objectTypeId
                );

            dispatch(successFetchDealWizServices(sortedServices));
            return sortedServices;
        } catch (e) {
            throw e;
        }
    };
}
