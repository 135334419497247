import {
    GET_COMMUNICATE_COMMUNICATION_SUCCESS,
    GET_COMMUNICATE_COMMUNICATIONS_SUCCESS,
    GET_NUMBER_OF_FLAGGED_GDPR_CONTACTS_SUCCESS,
    TOGGLE_GDPR_MODULE,
} from '../actions/communicate-old-actions';

const initialState = {
    flaggedGdprContacts: {
        hasBoth: null,
        emailCount: null,
        phoneNumberCount: null,
    },
    showGdprCommunicate: false,
    communications: [],
    communication: {
        contactGroups: [],
        methods: [],
        numContacts: 0,
        emailId: null,
        smsOutboxId: null,
    },
};

export const communicate = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_GDPR_MODULE:
            return {
                ...state,
                showGdprCommunicate: !state.showGdprCommunicate,
            };
        case GET_NUMBER_OF_FLAGGED_GDPR_CONTACTS_SUCCESS:
            return {
                ...state,
                flaggedGdprContacts: {
                    ...state.flaggedGdprContacts,
                    ...action.payload,
                },
            };
        case GET_COMMUNICATE_COMMUNICATION_SUCCESS:
            return {
                ...state,
                communication: action.payload,
            };
        case GET_COMMUNICATE_COMMUNICATIONS_SUCCESS:
            return {
                ...state,
                communications: [...state.communications, ...action.payload],
            };
        default:
            return state;
    }
};
