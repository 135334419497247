import React from 'react';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';
import { TEXT_STYLES } from '../typograph/typograph-components';

const TAG_SIZE_DEFAULT = '34px';
const TAG_SIZE_DESKTOP = '30px';

const ItemWrapper = styled.button`
    display: flex;
    cursor: pointer;
    box-shadow: none;
    padding-left: ${({ theme }) => `${theme.spacing.default}`};
    padding-right: 0;
    background: ${({ theme }) => theme.colors.bgSecondary140};
    color: ${({ theme }) => theme.colors.textPrimary};
    border-radius: ${({ theme }) => theme.borderRadius.default};
    border: none;
    align-items: center;
    height: ${TAG_SIZE_DEFAULT};
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        height: ${TAG_SIZE_DESKTOP};
    }
    &:hover {
        background: ${({ theme }) => theme.colors.primaryColor10};
        text-decoration: underline;
    }
`;
const RemoveIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 16px;
`;
const RemoveIconContainer = styled(Flex)`
    align-items: center;
    justify-content: center;
    width: 30px;
`;

const ValueText = styled.span`
    ${({ theme }) => TEXT_STYLES.helpingText(theme)};
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        ${({ theme }) => TEXT_STYLES.subTextMedium(theme)};
    }
`;

const RemovableValueTag = ({ value, onRemove, ...props }) => {
    if (!value) return null;

    return (
        <ItemWrapper {...props}>
            <ValueText>{value}</ValueText>
            <RemoveIconContainer>
                <RemoveIcon icon={faXmark} size="lg" />
            </RemoveIconContainer>
        </ItemWrapper>
    );
};
RemovableValueTag.propTypes = {
    value: PropTypes.string,
};

export default RemovableValueTag;
