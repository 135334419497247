import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { Flex, Box } from '@rebass/grid/emotion';
import {
    LoginContainer,
    InfoText,
    FormContent,
    LinkFooter,
    Link,
} from './styled-components';
import { GENERAL_TERMS_URL, HEADOFFICE_AGREEMENT_URL } from './constants';
import {
    Button,
    BUTTON_TYPES,
    ButtonToolbar,
    EmailInput,
    HelpBox,
} from '@mspecs/ui-components';
import { displayError } from '../notifications/services';

const Order = props => {
    const {
        registerEmail,
        sendEmailSuccess,
        sendEmailFail,
        usernameInUse,
        emailLinkIsNotValid,
        toggleLogin,
        t,
    } = props;

    useEffect(() => {
        if (usernameInUse) {
            displayError('REGISTER_USERNAME_IN_USE_ERROR', 5000);
            return;
        }

        if (sendEmailFail) {
            displayError('ORDER_MSPECS_ERROR_EMAIL_NOT_SENT', 5000);
            return;
        }

        if (emailLinkIsNotValid) {
            displayError('ORDER_MSPECS_ERROR_LINK_NOT_VALID', 5000);
            return;
        }
    });

    return (
        <LoginContainer
            topHeadline={t('ORDER_MSPECS_ORDER')}
            headline={t('ORDER_MSPECS_MSPECS')}
            register
        >
            <Box mx={[0, 30]} mb={10}>
                <InfoText>{t('ORDER_MSPECS_WELCOME')}</InfoText>
                <InfoText>{t('ORDER_MSPECS_INFO_TEXT')}</InfoText>
                <Flex flexDirection="column" pt={15} pb="5px">
                    <Link
                        text={t('GENERAL_TERMS')}
                        href={GENERAL_TERMS_URL}
                        target="_blank"
                    />
                    <Link
                        text={t('HEADOFFICE_AGREEMENT')}
                        href={HEADOFFICE_AGREEMENT_URL}
                        target="_blank"
                    />
                </Flex>
                {sendEmailSuccess && (
                    <HelpBox>
                        <div>{t('ORDER_MSPECS_THANK_YOU')}</div>
                        {t('ORDER_SPECS_MORE_INFO')}
                    </HelpBox>
                )}
            </Box>
            {!sendEmailSuccess && (
                <Form
                    onSubmit={registerEmail}
                    render={({ handleSubmit, invalid, submitting }) => (
                        <FormContent onSubmit={handleSubmit}>
                            <EmailInput
                                name="email"
                                label="EMAIL_ADDRESS"
                                autoComplete="email"
                                required
                                isFormField
                                hideRequiredHint
                                maxLength={80}
                                stackedLabel
                                autoFocus
                            />
                            <ButtonToolbar>
                                <Button
                                    label="SEND_LINK"
                                    type="submit"
                                    buttonType={BUTTON_TYPES.PRIMARY}
                                    disabled={invalid || submitting}
                                    large
                                />
                            </ButtonToolbar>
                        </FormContent>
                    )}
                />
            )}
            <LinkFooter back={toggleLogin} contactSupport />
        </LoginContainer>
    );
};

Order.propTypes = {
    registerEmail: PropTypes.func,
    sendEmailSuccess: PropTypes.bool,
    sendEmailFail: PropTypes.bool,
    usernameInUse: PropTypes.bool,
    emailLinkIsNotValid: PropTypes.bool,
    toggleLogin: PropTypes.func,
    t: PropTypes.func,
};

Order.defaultProps = {
    sendEmailSuccess: false,
    sendEmailFail: false,
    usernameInUse: false,
    emailLinkIsNotValid: false,
};

export default Order;
