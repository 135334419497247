import styled from '@emotion/styled';
import { ROW_HEIGHT, SEARCH_HEIGHT } from './styles-config';

const StyledMenu = styled.div<{
    invalid?: boolean;
    position?: 'left' | 'right';
    length: number;
    top: string;
    left: string;
}>`
    ${({
        theme: { colors, borderRadius, boxShadows, spacing },
        length,
        top,
        left,
    }) => `

    background: ${colors.bgPrimary};
    border-radius: ${borderRadius.default};
    box-shadow: ${boxShadows.default};
    margin-top: ${spacing.small};
    position: absolute;
    z-index: 2;
    height: ${length * ROW_HEIGHT + SEARCH_HEIGHT}px;
    max-height: 230px;
    width: 271px;
    top: ${top};
    left: ${left};
      `}
`;

export default StyledMenu;
