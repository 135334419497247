import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@rebass/grid/emotion';
import StatusTag from '../tag/status-tag-component';
import Logotype from '../../assets/images/mspecs-icon.png';
import {
    CardContainer,
    CardSubTitle,
    Logo,
    NotReadIndicator,
} from './message-card-styles';
import { Header5Text } from '../typograph/typograph-components';

const MessageCard = ({
    title,
    subTitle,
    withLogo,
    category,
    isRead,
    active,
}) => {
    const mapTextToStatus = text => {
        switch (text) {
            case 'Kommande':
                return 'highlight';
            case 'Information':
                return 'waiting';
            case 'Nya funktioner':
                return 'success';
            case 'Driftstopp':
                return 'error';
            default:
                return 'default';
        }
    };

    const status = mapTextToStatus(category);

    return (
        <CardContainer active={active}>
            <Flex>
                <Box width={'11px'} mr={10} alignSelf={'center'}>
                    {!isRead && (
                        <NotReadIndicator data-testid="not-read-indicator" />
                    )}
                </Box>
                <Box mr={10}>
                    <Flex flexDirection={'column'} alignItems={'flex-start'}>
                        <Header5Text>{title}</Header5Text>
                        {subTitle && <CardSubTitle>{subTitle}</CardSubTitle>}
                    </Flex>
                </Box>
                <Box ml={'auto'}>
                    <Flex flexDirection={'row'} alignItems={'flex-start'}>
                        {withLogo && (
                            <Logo src={Logotype} alt="Mspecs logotype" />
                        )}
                        {category && (
                            <StatusTag type={status}>{category}</StatusTag>
                        )}
                    </Flex>
                </Box>
            </Flex>
        </CardContainer>
    );
};

MessageCard.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    subTitle: PropTypes.string,
    withLogo: PropTypes.bool,
    category: PropTypes.string,
    isRead: PropTypes.bool,
    active: PropTypes.bool,
};

export default MessageCard;
