import { ac } from '../../utils/redux-utils';

export const ACTION_TYPES = {
    CLEAR_PAGE_TITLE: 'CLEAR_PAGE_TITLE',
    UPDATE_PAGE_TITLE: 'UPDATE_PAGE_TITLE',
};

export const updatePageTitle = title =>
    ac(ACTION_TYPES.UPDATE_PAGE_TITLE, title);

export const clearPageTitle = () => ac(ACTION_TYPES.CLEAR_PAGE_TITLE);
