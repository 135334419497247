import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { ButtonTextSmall } from '../../typograph/typograph-components';

const Container = styled(Flex)`
    height: 34px;
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    border: 1px solid ${({ theme }) => theme.colors.primaryColor20};
    &:has(> button.not-selected:hover) {
        background: ${({ theme }) => theme.colors.primaryColor10};
    }
`;

const Option = styled.button`
    display: flex;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    cursor: pointer;
    border: 2px solid
        ${({ theme, isSelected }) =>
            isSelected ? theme.colors.primaryColor20 : 'transparent'};
    background: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.primaryColor10 : 'transparent'};
    color: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.primaryColor : theme.colors.textSecondary};

    transition: background 0.2s ease, color 0.2s ease, border-color 0.2s ease;

    :active {
        background: ${({ theme }) => theme.colors.primaryColor20};
    }
`;
const IconWrapper = styled(Flex)`
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
`;
const OptionText = styled(ButtonTextSmall)`
    padding-right: ${({ theme }) => theme.spacing.default};
    color: inherit;
`;

export default function IconsToggleButton({
    options,
    selectedValue,
    setSelectedValue,
    ...props
}) {
    return (
        <Container {...props}>
            {options.map(option => (
                <Option
                    key={option.value}
                    onClick={() => setSelectedValue(option.value)}
                    isSelected={selectedValue === option.value}
                    className={
                        selectedValue === option.value
                            ? 'selected'
                            : 'not-selected'
                    }
                >
                    {option.text && <OptionText>{option.text}</OptionText>}
                    {option.icon && <IconWrapper>{option.icon}</IconWrapper>}
                </Option>
            ))}
        </Container>
    );
}

IconsToggleButton.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            icon: PropTypes.node,
            text: PropTypes.string,
        })
    ),
    selectedValue: PropTypes.string,
    setSelectedValue: PropTypes.func,
};

IconsToggleButton.defaultProps = {
    setSelectedValue: value => {},
    selectedValue: '',
    options: [],
};
