import { ROUTE_CONTACTS_BASE } from '~/routing/contact/routes';
import { ROUTE_OFFICE_SETTINGS_BASE } from '~/routing/settings/routes';

export const PRIVATE_LANDING_PAGE =
    __PRIVATE_LANDING_PAGE__ || '/app/dashboard';

export const PRIVATE_ROUTE_DASHBOARD = '/dashboard';
export const PRIVATE_ROUTE_DEALS = '/deals';
export const PRIVATE_ROUTE_DEALS_DETAILS = '/deal/:id';
export const PRIVATE_ROUTE_MATCHING_BASE = '/matching';
export const PRIVATE_ROUTE_MATCHING = `${PRIVATE_ROUTE_MATCHING_BASE}/:id`;

export const PRIVATE_ROUTE_CONTACTS = ROUTE_CONTACTS_BASE;
export const PRIVATE_ROUTE_CONTACTS_DETAILS = `${ROUTE_CONTACTS_BASE}/:id/*`;
export const PRIVATE_ROUTE_CONTACTS_ADD_NEW = `${ROUTE_CONTACTS_BASE}/add`;
export const PRIVATE_ROUTE_OFFICE_SETTINGS_ADD_NEW = `${ROUTE_OFFICE_SETTINGS_BASE}/add-new`;

export const PRIVATE_ROUTE_SETTINGS = '/settings';

export const PRIVATE_ROUTE_USERS = '/users';
export const PRIVATE_ROUTE_USERS_ADD_NEW = '/users/add';
export const PRIVATE_ROUTE_USERS_DETAILS = '/users/:id/*';
export const PRIVATE_ROUTE_COMMUNICATE_HISTORY = '/communicate-history';
export const PRIVATE_ROUTE_FILTERS = '/filters';
export const PRIVATE_ROUTE_OFFICE365 = '/office365';
export const PRIVATE_ROUTE_FUTURE_VIEWINGS = '/futureviewings';
export const PRIVATE_ROUTE_PROPERTY_LIST_FOLLOW_UP = '/follow-up-list';
export const PRIVATE_ROUTE_NEWSPAPER_ADS = '/newspaper-ads-list';
export const PRIVATE_ROUTE_INVOICES = '/invoices';
export const PRIVATE_ROUTE_ASSISTANT = '/assistant';
export const PRIVATE_ROUTE_RECEIPTS = '/receipts';
export const PRIVATE_ROUTE_HOUSING_ASSOCIATION = '/housingassociation';
export const PRIVATE_ROUTE_SUPPLIERS = '/suppliers';
export const PRIVATE_ROUTE_REPORTS = '/report-page';
export const PRIVATE_ROUTE_DOCUMENTS = '/documents';
export const PRIVATE_ROUTE_SYSTEM_INFORMATION = '/system-information';
export const PRIVATE_ROUTE_SEARCH_RESULTS_DEALS = '/search-results/deals';
export const PRIVATE_ROUTE_SEARCH_RESULTS_CONTACTS = '/search-results/contacts';

// Admin routes
export const PRIVATE_ROUTE_ADMIN = '/admin';
export const PRIVATE_ROUTE_ADMIN_ORGANIZATION_MANAGER_SUFFIX = `/organization-manager`;
export const PRIVATE_ROUTE_ADMIN_ORGANIZATION_MANAGER = `${PRIVATE_ROUTE_ADMIN}/${PRIVATE_ROUTE_ADMIN_ORGANIZATION_MANAGER_SUFFIX}`;
export const PRIVATE_ROUTE_ADMIN_WALL_SUFFIX = '/wall';
export const PRIVATE_ROUTE_ADMIN_WALL = `${PRIVATE_ROUTE_ADMIN}/${PRIVATE_ROUTE_ADMIN_WALL_SUFFIX}`;
export const PRIVATE_ROUTE_ADMIN_MSPX_ID_DECODER_SUFFIX = '/id-decoder';
export const PRIVATE_ROUTE_ADMIN_MSPX_ID_DECODER = `${PRIVATE_ROUTE_ADMIN}/${PRIVATE_ROUTE_ADMIN_MSPX_ID_DECODER_SUFFIX}`;
export const PRIVATE_ROUTE_LOGIN_AS_USER_SUFFIX = '/login-as';
export const PRIVATE_ROUTE_LOGIN_AS_USER = `${PRIVATE_ROUTE_ADMIN}/${PRIVATE_ROUTE_LOGIN_AS_USER_SUFFIX}`;
export const PRIVATE_ROUTE_LOGIN_AS_USER_ORGANIZATION_SELECTED = `${PRIVATE_ROUTE_LOGIN_AS_USER_SUFFIX}/:organizationId`;
export const PRIVATE_ROUTE_ADMIN_FEATURE_TOOGLE_SUFFIX = `feature-toggle`;
export const PRIVATE_ROUTE_ADMIN_FEATURE_TOOGLE = `${PRIVATE_ROUTE_ADMIN}/${PRIVATE_ROUTE_ADMIN_FEATURE_TOOGLE_SUFFIX}`;
export const PRIVATE_ROUTE_ADMIN_FEATURE_TOOGLE_ORGANIZATION_SELECTED = `${PRIVATE_ROUTE_ADMIN_FEATURE_TOOGLE_SUFFIX}/:organizationId`;
export const PRIVATE_ROUTE_ADMIN_INVOICE_STATS_SUFFIX = `upcoming-invoice-stats`;
export const PRIVATE_ROUTE_ADMIN_INVOICE_STATS = `${PRIVATE_ROUTE_ADMIN}/${PRIVATE_ROUTE_ADMIN_INVOICE_STATS_SUFFIX}`;
