import React from 'react';
import { PATTERN } from '@mspecs/shared-utils';
import { InputField } from '.';
import { FormInputField } from '../form';
import { PropTypes } from 'prop-types';

const EmailInput = props => {
    const { isFormField } = props;
    if (isFormField) {
        return (
            <FormInputField
                {...props}
                name="email"
                validationPttrn={PATTERN.EMAIL}
                errorMessage="VALIDATION_ERROR_EMAIL"
            />
        );
    }
    return <InputField name="email" {...props} />;
};

EmailInput.propTypes = {
    isFormField: PropTypes.bool,
};

export default EmailInput;
