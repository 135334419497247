import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';

const Container = styled(Flex)`
    flex-wrap: no-wrap;
    button {
        :not(:first-of-type) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: initial;
        }
        :not(:last-of-type) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
`;

const ButtonGroup = props => {
    return <Container {...props} />;
};
export default ButtonGroup;
