import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import MenuItem from './content-menu-item-component';
import { useElementSize } from '@mspecs/shared-utils';
import { StyledToggleIcon, ToggleMenuItem } from './content-menu-component';
import { useTranslate } from '@mspecs/shared-utils';
import { useIsMobile } from '@mspecs/shared-utils';
import { HeightTransition } from '../../animations';

const MainItemsContainer = styled.div`
    background: ${({ theme }) => theme.colors.bgPrimary};
    border-radius: ${({ theme }) => theme.borderRadius.default};
    overflow: hidden;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        background: ${({ theme }) => theme.colors.primaryColor5};
        border-radius: ${({ theme }) => theme.borderRadius.medium};
    }
`;

const NavItems = ({ items }) => {
    const { t } = useTranslate();
    const isSmall = useIsMobile();
    return (
        <>
            {items?.map(item => (
                <MenuItem key={item.label} to={item.path} size="small">
                    {isSmall ? t(item.label) : t(item.labelShort)}
                </MenuItem>
            ))}
        </>
    );
};

NavItems.propTypes = {
    items: PropTypes.array,
};

const MainItems = ({ mainNavItems, currentMainItemLabel }) => {
    const isSmall = useIsMobile();
    const [mainItemsRef, { height: mainItemsHeight }] = useElementSize();
    const [showMainItems, setShowMainItems] = useState(false);

    const handleToggleMainItems = useCallback(() => {
        setShowMainItems(prevShowMainItems => !prevShowMainItems);
    }, []);

    useEffect(() => {
        setShowMainItems(false);
    }, [currentMainItemLabel]);

    if (!mainNavItems) {
        return null;
    }

    if (!isSmall) return <NavItems items={mainNavItems} />;

    return (
        <>
            <ToggleMenuItem onClick={handleToggleMainItems}>
                {currentMainItemLabel}
                <StyledToggleIcon
                    icon={faAngleUp}
                    toggled={showMainItems ? 'true' : 'false'}
                    size="lg"
                />
            </ToggleMenuItem>
            <HeightTransition
                on={showMainItems}
                height={`${mainItemsHeight}px`}
            >
                {transitionProps => (
                    <div {...transitionProps}>
                        <MainItemsContainer ref={mainItemsRef}>
                            <NavItems items={mainNavItems} />
                        </MainItemsContainer>
                    </div>
                )}
            </HeightTransition>
        </>
    );
};

MainItems.propTypes = {
    mainNavItems: PropTypes.array,
    currentMainItemLabel: PropTypes.string,
};

export default MainItems;
