import React, { useCallback, useState } from 'react';
import { Flex, Box } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WizStep, { STEP_STATES } from './vertical-wiz-step-component';
import { HeightTransition } from '../../../animations';

const Container = styled(Flex)`
    height: 100%;
    width: 100%;
    flex-direction: column;
`;
const ConnectionLineWrapper = styled(Flex)`
    width: 24px;
    justify-content: center;
    margin: ${({ theme }) => theme.spacing.small} 0;
    min-height: ${({ theme }) => theme.spacing.default};
`;
const ConnectionLine = styled(Flex)`
    width: 1.5px;
    background: ${({ theme }) => theme.colors.borderPrimary};
`;

const StepContainer = styled(Box)`
    align-items: center;
`;
const Content = styled(Box)`
    width: 100%;
    padding: ${({ theme: { spacing } }) =>
        `${spacing.default} 0 ${spacing.default} ${spacing.default}`};
`;

const ContentWrapper = styled(Flex)`
    flex-direction: column;
    overflow: auto;
    height: 100%;
`;

export default function VerticalWiz({
    steps,
    renderToolbar,
    renderStatusView,
    children,
}) {
    const [activeStep, setActiveStep] = useState(1);
    const [completedSteps, setCompletedSteps] = useState([]);
    const isLastStep = activeStep === steps.length;
    const isFirstStep = activeStep === 1;

    const getStepState = useCallback(
        stepCount => {
            let state;
            const maxPossibleStep = Math.max(...completedSteps) + 1;

            if (completedSteps.includes(stepCount)) {
                state = STEP_STATES.COMPLETED;
            }
            if (stepCount === activeStep) {
                state = STEP_STATES.ACTIVE;
            }
            if (stepCount > 1 && stepCount > maxPossibleStep) {
                state = STEP_STATES.DISABLED;
            }
            return state;
        },
        [completedSteps, activeStep]
    );

    const onNextClick = () => {
        if (isLastStep) return;

        setCompletedSteps(prev => [...prev, activeStep]);
        setActiveStep(prev => prev + 1);
    };
    const onBackClick = () => {
        if (isFirstStep) return;
        setActiveStep(prev => prev - 1);
    };

    const statusView = renderStatusView?.();
    // Need to render this inside the wiz, orherwise the local state will be erased if unmounted
    if (statusView) return statusView;

    return (
        <Container>
            <ContentWrapper>
                {steps
                    .map((step, index) => ({ ...step, stepCount: index + 1 }))
                    .map(({ label, stepCount, component: Component }) => (
                        <StepContainer key={label}>
                            <WizStep
                                label={label}
                                stepCount={stepCount}
                                state={getStepState(stepCount)}
                                onClick={() => setActiveStep(stepCount)}
                            />
                            <Flex>
                                <ConnectionLineWrapper>
                                    {stepCount < steps.length && (
                                        <ConnectionLine />
                                    )}
                                </ConnectionLineWrapper>

                                <HeightTransition on={activeStep === stepCount}>
                                    {transitionProps => (
                                        <Content {...transitionProps}>
                                            {Component}
                                        </Content>
                                    )}
                                </HeightTransition>
                            </Flex>
                        </StepContainer>
                    ))}
                {children}
            </ContentWrapper>
            {renderToolbar?.({
                activeStep,
                isLastStep,
                isFirstStep,
                backButtonProps: {
                    onClick: onBackClick,
                    label: 'BACK',
                },
                nextButtonProps: {
                    onClick: onNextClick,
                    label: 'NEXT',
                },
            })}
        </Container>
    );
}

export const StepPropType = PropTypes.shape({
    label: PropTypes.string,
    component: PropTypes.any,
});

VerticalWiz.propTypes = {
    steps: PropTypes.arrayOf(StepPropType),
    renderToolbar: PropTypes.func,
    renderStatusView: PropTypes.func,
    children: PropTypes.any,
};
