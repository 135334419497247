import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Button from './button-component';

// Styled component for IconButtonToolbar
const IconButtonToolbarContainer = styled(Button)`
    background: ${({ theme }) => theme.colors.white};
    border: none;
    width: 34px;
    height: 34px;
    border-radius: ${({ theme }) => theme.borderRadius.default};
    padding: 0;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.textPrimary};

    &:hover,
    &:focus {
        color: ${({ theme }) => theme.colors.textPrimary};
        background: ${({ theme, variant }) =>
            variant === 'blue'
                ? theme.colors.primaryColor10
                : theme.colors.bgSecondary120};
    }

    &:focus {
        box-shadow: none;
        border: 2px solid
            ${({ theme, variant }) =>
                variant === 'blue'
                    ? theme.colors.primaryColor20
                    : theme.colors.gray80};
    }

    &:active,
    &:focus:active {
        border-color: ${({ theme, variant }) =>
            variant === 'blue'
                ? theme.colors.primaryColor20
                : theme.colors.gray80};
        color: ${({ theme }) => theme.colors.textPrimary};
        background: ${({ theme, variant }) =>
            variant === 'blue'
                ? theme.colors.primaryColor20
                : theme.colors.gray80};
    }

    @media (min-width: ${({ theme }) => theme.medium}) {
        width: 42px;
        height: ${({ buttonStyle }) => buttonStyle?.height ?? '42px'};
        vertical-align: middle;
    }
`;

// IconButtonToolbar functional component
export const IconButtonToolbar = ({ children, variant = 'gray', ...rest }) => {
    return (
        <IconButtonToolbarContainer variant={variant} {...rest}>
            {children}
        </IconButtonToolbarContainer>
    );
};

IconButtonToolbar.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.oneOf(['blue', 'gray']),
};
