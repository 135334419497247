import { ac } from '../../utils/redux-utils';

export const ACTION_TYPES = {
    REQUEST_FAILED: 'REQUEST_FAILED',
    REQUEST_SUCCESS: 'REQUEST_SUCCESS',
    REQUEST_STARTED: 'REQUEST_STARTED',
    REQUEST_FINISHED: 'REQUEST_FINISHED',
};

export const failRequest = err => ac(ACTION_TYPES.REQUEST_FAILED, err);
export const successRequest = url => ac(ACTION_TYPES.REQUEST_SUCCESS, url);
export const startRequest = url => ac(ACTION_TYPES.REQUEST_STARTED, url);
export const finishRequest = url => ac(ACTION_TYPES.REQUEST_FINISHED, url);
