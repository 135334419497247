import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import Button, { BUTTON_TYPES } from '../button-component';
import {
    BodyTextRegular,
    HighlightedBodyTextBold,
} from '../../typograph/typograph-components';

const IconWrapper = styled(Flex)`
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    margin-right: 10px;
    svg {
        height: 20px;
    }
`;
const ButtonText = styled(HighlightedBodyTextBold)``;

const StyledButton = styled(Button, {
    shouldForwardProp: prop => !['hasIcon'].includes(prop),
})`
    text-decoration: ${({ hasIcon }) => (hasIcon ? 'none' : 'underline')};
    text-underline-offset: ${({ hasIcon }) => !hasIcon && '3px'};

    :hover {
        text-underline-offset: ${({ hasIcon }) => hasIcon && 'unset'};
        text-decoration-thickness: ${({ hasIcon }) => hasIcon && 'unset'};
        text-decoration: ${({ hasIcon }) => hasIcon && 'none'};
        color: inherit;
    }
`;

const ClickableText = ({ text, icon, textType, ...props }) => {
    return (
        <StyledButton
            buttonType={BUTTON_TYPES.TEXT}
            hasIcon={Boolean(icon)}
            {...props}
        >
            {icon && <IconWrapper>{icon}</IconWrapper>}
            {textType === 'regular' ? (
                <BodyTextRegular>{text}</BodyTextRegular>
            ) : (
                <ButtonText>{text}</ButtonText>
            )}
        </StyledButton>
    );
};

export default ClickableText;

ClickableText.propTypes = {
    text: PropTypes.string.isRequired,
    textType: PropTypes.string,
    icon: PropTypes.node,
};
