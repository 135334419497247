import { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import {
    Toolbar,
    ToolbarButton,
} from '../layout/form/form-button-toolbar-component';
import { useTranslate } from '@mspecs/shared-utils';
import DraggableCheckboxTree from '../checkboxtree/draggable-checkboxtree-component';
import { BodyTextRegular } from '../typograph/typograph-components';
import { DragDropContext } from 'react-beautiful-dnd';
import useColumnPicker, { COLUMN_TYPES } from './hooks/useColumnPicker';
import { BUTTON_TYPES } from '../button';

const BottomBar = styled.div`
    margin-top: auto;
`;

const OverflowWrapper = styled.div`
    overflow-y: scroll;
    height: 100%;
`;
const StyledButton = styled(ToolbarButton)`
    text-transform: none;
    width: calc(50% - 10px);
`;

const Container = styled(Flex)`
    flex: 1 1 0;
    flex-direction: column;
    overflow: hidden;
`;

const Intro = styled(BodyTextRegular)`
    margin: ${props => props.theme.spacing.large} 0;
`;

const ColumnPicker = ({
    onClose,
    onSubmit,
    columns,
    stickyColumns,
    scrollableColumns,
    selectedColumns,
    setStickyColumns,
    setScrollableColumns,
    setSelectedColumns,
    lockedColumn = 'displayName',
}) => {
    const checkboxTreeRef = useRef(null);
    const { t } = useTranslate();
    const [checked, setChecked] = useState(selectedColumns);

    const {
        updatedStickyColumns,
        updatedScrollableColumns,
        handleChangeColumnsOnDragEnd,
    } = useColumnPicker(
        stickyColumns,
        scrollableColumns.length === 0 ? columns : scrollableColumns,
        lockedColumn
    );

    useEffect(() => {
        checkboxTreeRef.current = scrollableColumns.map(o => o.accessor);
    }, [scrollableColumns]);

    const handleSubmit = () => {
        setStickyColumns(updatedStickyColumns);
        setScrollableColumns(updatedScrollableColumns);
        const flatItems = columns.reduce(
            (acc, curr) => [...acc, ...(curr?.items ?? [curr])],
            []
        );
        const updatedColumns = flatItems
            .filter(column => checked.includes(column.value))
            .map(column => column.value);
        setSelectedColumns(updatedColumns);
        onSubmit(updatedColumns);
    };

    return (
        <Container>
            <OverflowWrapper>
                <Intro>{t('COLUMN_PICKER_HELP_TEXT')}</Intro>
                <DragDropContext onDragEnd={handleChangeColumnsOnDragEnd}>
                    {updatedStickyColumns && (
                        <DraggableCheckboxTree
                            onChecked={setChecked}
                            checked={checked}
                            nodes={updatedStickyColumns}
                            ref={checkboxTreeRef}
                            id={COLUMN_TYPES.STICKY}
                            title={t('TABLE_MOVE_COLUMNS_STATIC_COLUMNS')}
                        />
                    )}
                    {updatedScrollableColumns && (
                        <DraggableCheckboxTree
                            onChecked={setChecked}
                            checked={checked}
                            nodes={updatedScrollableColumns}
                            ref={checkboxTreeRef}
                            id={COLUMN_TYPES.SCROLLABLE}
                            title={t('TABLE_MOVE_COLUMNS_SCROLLABLE_COLUMNS')}
                        />
                    )}
                </DragDropContext>
            </OverflowWrapper>
            <BottomBar>
                <Toolbar>
                    <StyledButton fluid onClick={onClose}>
                        COLUMN_PICKER_CLOSE_BUTTON_LABEL
                    </StyledButton>
                    <StyledButton
                        disabled={!checked.length}
                        fluid
                        onClick={handleSubmit}
                        buttonType={BUTTON_TYPES.PRIMARY}
                    >
                        COLUMN_PICKER_APPLY_BUTTON_LABEL
                    </StyledButton>
                </Toolbar>
            </BottomBar>
        </Container>
    );
};

ColumnPicker.propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    columns: PropTypes.array,
    stickyColumns: PropTypes.array,
    scrollableColumns: PropTypes.array,
    selectedColumns: PropTypes.array,
    setStickyColumns: PropTypes.func,
    setScrollableColumns: PropTypes.func,
    setSelectedColumns: PropTypes.func,
    lockedColumn: PropTypes.string,
};

export default ColumnPicker;
