import { v1 as uuidv1 } from 'uuid';
import { TOAST_TYPES } from '@mspecs/ui-components/src/components/toast/constants';
import { toastsVar } from '../stores';

function createToastObject(message, type) {
    return {
        id: uuidv1(),
        title: message.title,
        body: message.body,
        type,
    };
}

const prepareToast = newToast => {
    const toasts = toastsVar();
    return {
        ...toasts,
        [newToast.id]: newToast,
    };
};

const displayToast = (toast, timer = 4000) => {
    toastsVar(prepareToast(toast));
    setTimeout(() => {
        removeToast(toast);
    }, timer);
};

const createAndDisplayToast = type => (message, timer) => {
    const defaultMessage = {
        title: '',
        body: '',
    };

    const mergedMessage = { ...defaultMessage, ...message };

    return displayToast(createToastObject(mergedMessage, type), timer);
};

export const removeToast = deleteToast => {
    const toasts = toastsVar();

    const { [deleteToast.id]: deleted, ...messagesWithoutDeleted } = toasts;
    toastsVar({
        ...messagesWithoutDeleted,
    });
};

export const displayLoadingToast = createAndDisplayToast(TOAST_TYPES.LOADING);
export const displaySuccessToast = createAndDisplayToast(TOAST_TYPES.SUCCESS);
export const displayErrorToast = createAndDisplayToast(TOAST_TYPES.ERROR);
export const displayWarningToast = createAndDisplayToast(TOAST_TYPES.WARNING);
export const displayInfoToast = createAndDisplayToast(TOAST_TYPES.INFO);
