import { displayError } from '~/features/notifications/services';
import { ac } from '../../../utils/redux-utils';
import { legacyApi } from '@mspecs/shared-utils';
import {
    notificationUpdateSuccess,
    notificationUpdateError,
    notificationCreateSuccess,
    notificationCreateError,
    notificationDeleteSuccess,
    notificationDeleteError,
    notificationFetchError,
} from '~/utils/notification-utils';

export const ACTION_TYPES = {
    GET_PROVIDER_DATA_SUCCESS: 'GET_PROVIDER_DATA_SUCCESS',
    CREATE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS:
        'CREATE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS',
    REMOVE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS:
        'REMOVE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS',
    PROVIDER_SERVICE_ERROR: 'PROVIDER_SERVICE_ERROR',
    PROVIDER_SERVICE_REQUEST_START: 'PROVIDER_SERVICE_REQUEST_START',
    SET_SELECTED_PROVIDER_SERVICE: 'SET_SELECTED_PROVIDER_SERVICE',
    GET_OBJECT_TYPES_SUCCESS: 'GET_OBJECT_TYPES_SUCCESS',
    UPDATE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS:
        'UPDATE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS',
};
const startProviderServiceRequst = () =>
    ac(ACTION_TYPES.PROVIDER_SERVICE_REQUEST_START);

const providerServiceError = () => ac(ACTION_TYPES.PROVIDER_SERVICE_ERROR);

const getProviderDataSuccess = providerServices =>
    ac(ACTION_TYPES.GET_PROVIDER_DATA_SUCCESS, providerServices);

const getObjectTypesSuccess = providerServices =>
    ac(ACTION_TYPES.GET_OBJECT_TYPES_SUCCESS, providerServices);

const createProviderServiceSubscriptionSuccess = subscribeProviderService =>
    ac(
        ACTION_TYPES.CREATE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS,
        subscribeProviderService
    );

const updateProviderServiceSubscriptionSuccess = subscribeProviderService =>
    ac(
        ACTION_TYPES.UPDATE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS,
        subscribeProviderService
    );

const removeProviderServiceSubscriptionSuccess = subscriptions =>
    ac(
        ACTION_TYPES.REMOVE_PROVIDER_SERVICE_SUBSCRIPTION_SUCCESS,
        subscriptions
    );

const setSelectedProviderService = providerService =>
    ac(ACTION_TYPES.SET_SELECTED_PROVIDER_SERVICE, providerService);

const handleError = dispatch => (e, messageDispatcher) => {
    dispatch(providerServiceError());
    if (e && e.message) {
        displayError(e.message);
    } else {
        messageDispatcher(dispatch);
    }
    throw e;
};

const filterProviderSet = obj => obj.provider;

export const setSelectedProviderServiceId = providerServiceId => {
    return async dispatch => {
        try {
            if (!providerServiceId) {
                dispatch(setSelectedProviderService(null));
                return;
            }

            dispatch(startProviderServiceRequst());

            const providerService = await legacyApi(
                `/v3/providerService/${providerServiceId}`
            ).get();

            dispatch(setSelectedProviderService(providerService));
        } catch (e) {
            dispatch(providerServiceError());
            notificationFetchError();
            throw e;
        }
    };
};

export function getProviderData(organizationUnitId) {
    return async (dispatch, getState) => {
        try {
            dispatch(startProviderServiceRequst());
            const providerServices = await legacyApi('/providerServices').get();
            const providers = await legacyApi('/providers').get();
            const legacyServices = await legacyApi(
                '/services?showAll=true&q=isSystemService = false'
            ).get();
            const blacklistedProviders = await legacyApi(
                '/blacklistedProviders'
            ).get();
            let subscriptions = await legacyApi(
                '/providerServiceSubscribers'
            ).get();

            if (organizationUnitId) {
                const organizationProviderServices = await legacyApi(
                    `/v3/providerService/organizationUnits/${organizationUnitId}`
                ).get();

                subscriptions = subscriptions.map(subscription => {
                    const {
                        organizationUnitSubscription = [],
                        organizationSubscription = [],
                    } = organizationProviderServices;
                    const orgUnitSub = organizationUnitSubscription.find(
                        unit => unit?.id === subscription?.id
                    );
                    const orgSub = organizationSubscription.find(
                        org => org?.id === subscription?.id
                    );

                    return {
                        ...subscription,
                        subscriptionType: orgUnitSub
                            ? 'organizationUnitSubscription'
                            : orgSub
                            ? 'organizationSubscription'
                            : undefined,
                    };
                });
            }

            const organizationId = getState().session.organization.id;

            const providerData = {
                providerServices: providerServices.map(providerService => ({
                    ...providerService,
                    provider: providers.find(
                        provider => provider?.id === providerService?.providerId
                    ),
                    subscription: subscriptions.find(
                        subscriber =>
                            providerService?.id ===
                            subscriber?.providerServiceId
                    ),
                })),
                providers: providers.map(provider => ({
                    ...provider,
                    blacklistId: blacklistedProviders.find(
                        blacklistedProvider =>
                            blacklistedProvider.providerId === provider.id
                    )?.id,
                })),
                legacyServices: legacyServices
                    .map(legacyService => ({
                        ...legacyService,
                        provider: providers.find(
                            provider => provider.id === legacyService.providerId
                        ),
                        blacklisted: Boolean(
                            blacklistedProviders.find(
                                blacklistedProvider =>
                                    blacklistedProvider.id ===
                                    legacyService.providerId
                            )
                        ),
                    }))
                    .filter(filterProviderSet),
            };

            providerData.subscriptions = subscriptions
                .map(subscription => ({
                    ...subscription,
                    providerService: providerData.providerServices.find(
                        providerService =>
                            providerService?.id ===
                            subscription?.providerServiceId
                    ),
                }))
                .filter(subscription => subscription.providerService);

            dispatch(getProviderDataSuccess(providerData));
        } catch (e) {
            dispatch(providerServiceError());
            notificationFetchError();
            throw e;
        }
    };
}

export function getObjectTypes() {
    return async dispatch => {
        try {
            dispatch(startProviderServiceRequst());
            const objectTypes = await legacyApi('/objectTypes').get();

            dispatch(getObjectTypesSuccess(objectTypes));
        } catch (e) {
            dispatch(providerServiceError());
            notificationFetchError();
            throw e;
        }
    };
}

export function createProviderServiceSubscription(
    providerServiceId,
    isOrganizationUnitSubscription,
    mandatoryObjectTypes,
    recommendedObjectTypes,
    organizationUnitId
) {
    return async (dispatch, getState) => {
        const { providerServices: providerServicesData } = getState();

        try {
            dispatch(startProviderServiceRequst());
            const createProviderServiceSubscription = await legacyApi(
                '/providerServiceSubscribers'
            ).post({
                providerServiceId,
                isOrganizationUnitSubscription,
                accessGrants: undefined,
                recommendations: !organizationUnitId
                    ? {
                          mandatory: mandatoryObjectTypes,
                          recommended: recommendedObjectTypes,
                      }
                    : undefined,
            });

            createProviderServiceSubscription.providerService =
                providerServicesData.providerServices.find(
                    providerService => providerService.id === providerServiceId
                );

            if (organizationUnitId) {
                await legacyApi(
                    `/${createProviderServiceSubscription.id}/organizationUnit/${organizationUnitId}`
                ).post({
                    recommendations: organizationUnitId && {
                        mandatory: mandatoryObjectTypes,
                        recommended: recommendedObjectTypes,
                    },
                });
            }

            dispatch(
                createProviderServiceSubscriptionSuccess(
                    createProviderServiceSubscription
                )
            );
            dispatch(getProviderData(organizationUnitId));
            notificationCreateSuccess();
        } catch (e) {
            const reportError = handleError(dispatch);
            reportError(e, notificationCreateError);
        }
    };
}

export function updateProviderServiceSubscription(
    providerServiceSubscriptionId,
    isOrganizationUnitSubscription,
    mandatoryObjectTypes,
    recommendedObjectTypes,
    organizationUnitId,
    providerServiceId
) {
    return async dispatch => {
        try {
            dispatch(startProviderServiceRequst());
            await legacyApi(
                `/providerServiceSubscribers/${providerServiceSubscriptionId}`
            ).put({
                isOrganizationUnitSubscription,
                accessGrants: undefined,
                recommendations: !organizationUnitId
                    ? {
                          mandatory: mandatoryObjectTypes,
                          recommended: recommendedObjectTypes,
                      }
                    : undefined,
            });

            if (organizationUnitId) {
                const providerServiceSubscriptionRecommendation =
                    await legacyApi(
                        `/v3/providerService/${providerServiceId}/organizationUnit/${organizationUnitId}`
                    ).get();

                const url = `/v3/providerService/${providerServiceId}/organizationUnit/${organizationUnitId}`;
                const body = {
                    recommendations: {
                        mandatory: mandatoryObjectTypes,
                        recommended: recommendedObjectTypes,
                    },
                };

                if (providerServiceSubscriptionRecommendation.id) {
                    await legacyApi(url).put(body);
                } else {
                    await legacyApi(url).post(body);
                }
            }

            dispatch(
                updateProviderServiceSubscriptionSuccess(
                    providerServiceSubscriptionId,
                    isOrganizationUnitSubscription,
                    organizationUnitId,
                    mandatoryObjectTypes,
                    recommendedObjectTypes
                )
            );
            dispatch(getProviderData(organizationUnitId));
            notificationUpdateSuccess();
        } catch (e) {
            dispatch(providerServiceError());
            if (e && e.message) {
                displayError(e.message);
            } else {
                notificationUpdateError();
            }
            throw e;
        }
    };
}

export function removeProviderServiceSubscription(
    subscriberId,
    organizationUnitId,
    providerServiceId
) {
    return async (dispatch, getState) => {
        try {
            const { providerServices } = getState();
            dispatch(startProviderServiceRequst());
            if (organizationUnitId) {
                await legacyApi(
                    `/v3/providerService/${providerServiceId}/organizationUnit/${organizationUnitId}`
                ).delete();
            } else {
                await legacyApi(
                    `/providerServiceSubscribers/${subscriberId}`
                ).delete();
            }

            const fiteredServices = providerServices.subscriptions.filter(
                subscription => subscription.id !== subscriberId
            );

            dispatch(removeProviderServiceSubscriptionSuccess(fiteredServices));
            dispatch(getProviderData(organizationUnitId));
            notificationDeleteSuccess();
        } catch (e) {
            const reportError = handleError(dispatch);
            reportError(e, notificationDeleteError);
        }
    };
}

export function updateProviderService(
    providerService,
    providerServiceId,
    organizationUnitId
) {
    return async dispatch => {
        try {
            dispatch(startProviderServiceRequst());

            delete providerService.type;

            await legacyApi(
                `/integration/api/provider/services/${providerServiceId}`
            ).put({
                ...providerService,
                objectTypes: providerService.objectTypes ?? [],
            });

            dispatch(getProviderData(organizationUnitId));
            notificationUpdateSuccess();
        } catch (e) {
            const reportError = handleError(dispatch);
            reportError(e, notificationUpdateError);
        }
    };
}

export function createProviderService(providerService, organizationUnitId) {
    return async dispatch => {
        try {
            dispatch(startProviderServiceRequst());

            await legacyApi(`/integration/api/provider/services`).post({
                ...providerService,
                objectTypes: providerService.objectTypes ?? [],
            });

            dispatch(getProviderData(organizationUnitId));
            notificationCreateSuccess();
        } catch (e) {
            const reportError = handleError(dispatch);
            reportError(e, notificationCreateError);
        }
    };
}

export function removeProviderService(providerServiceId, organizationUnitId) {
    return async dispatch => {
        try {
            dispatch(startProviderServiceRequst());

            await legacyApi(
                `/integration/api/provider/services/${providerServiceId}`
            ).delete();

            dispatch(getProviderData(organizationUnitId));
            notificationDeleteSuccess();
        } catch (e) {
            const reportError = handleError(dispatch);
            reportError(e, notificationDeleteError);
        }
    };
}

export function hideLegacyService(providerId, organizationUnitId) {
    return async dispatch => {
        try {
            dispatch(startProviderServiceRequst());

            await legacyApi('/blacklistedProviders').post({
                providerId,
            });

            dispatch(getProviderData(organizationUnitId));
            notificationUpdateSuccess();
        } catch (e) {
            const reportError = handleError(dispatch);
            reportError(e, notificationUpdateError);
        }
    };
}

export function showLegacyService(providerId, organizationUnitId) {
    return async dispatch => {
        try {
            dispatch(startProviderServiceRequst());

            await legacyApi(`/blacklistedProviders/${providerId}`).delete();

            dispatch(getProviderData(organizationUnitId));
            notificationUpdateSuccess();
        } catch (e) {
            const reportError = handleError(dispatch);
            reportError(e, notificationUpdateError);
        }
    };
}
