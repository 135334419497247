import React, { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';
import { css } from '@emotion/react';
import TableBodyRow from './table-body-row-component';
import { useResizableListSize } from '@mspecs/shared-utils';
import ResizeableList from '../../../lists/resizeable-list-component';

const InfiniteScrollBody = ({
    data,
    totalCount,
    isItemLoaded,
    loading,
    fetchingMore,
    showPagination,
    prepareRow,
    page,
    rows,
    getRowProps,
    loadMoreItems,
    defaultItemSize = 56,
    sortBy,
}) => {
    const itemCount = totalCount > rows.length ? rows.length + 1 : totalCount;
    const infiniteLoaderRef = useRef(null);

    const { setSize, getItemSize, listRef } = useResizableListSize({
        defaultSize: defaultItemSize,
    });

    const loadMoreItemsFunc =
        loading || fetchingMore ? () => {} : loadMoreItems;

    useEffect(() => {
        infiniteLoaderRef?.current?.resetloadMoreItemsCache();
    }, [sortBy]);

    const Row = forwardRef(({ style, index }, ref) => {
        const rowRef = useRef();
        const row = (showPagination ? page : rows)[index];
        const rowWidth = rowRef?.current?.getBoundingClientRect().width;

        useEffect(() => {
            const rowHeight = rowRef?.current?.getBoundingClientRect().height;
            if (rowHeight && rowHeight !== getItemSize(index)) {
                setSize(index, rowHeight);
            }
        }, [setSize, index, rowWidth, getItemSize]);

        return (
            <TableBodyRow
                ref={ref}
                row={row}
                index={index}
                style={style}
                getRowProps={getRowProps}
                prepareRow={prepareRow}
                isItemLoaded={isItemLoaded}
                outerRef={rowRef}
            />
        );
    });

    return (
        <InfiniteLoader
            ref={infiniteLoaderRef}
            itemCount={itemCount}
            isItemLoaded={index => !loading && data[index]}
            loadMoreItems={loadMoreItemsFunc}
        >
            {({ onItemsRendered, ref }) => (
                <ResizeableList
                    scrollSync
                    ref={list => {
                        ref(list);
                        listRef.current = list;
                    }}
                    itemKey={index => {
                        const item = rows[index];
                        return item?.id || index;
                    }}
                    itemCount={itemCount || 0}
                    itemSize={getItemSize}
                    onItemsRendered={onItemsRendered}
                    css={css`
                        overscroll-behavior: none;
                    `}
                    loading={loading}
                >
                    {Row}
                </ResizeableList>
            )}
        </InfiniteLoader>
    );
};

InfiniteScrollBody.propTypes = {
    data: PropTypes.array,
    totalCount: PropTypes.number,
    isItemLoaded: PropTypes.func,
    showPagination: PropTypes.bool,
    prepareRow: PropTypes.func,
    page: PropTypes.number,
    rows: PropTypes.array,
    getRowProps: PropTypes.func,
    loadMoreItems: PropTypes.func,
    loading: PropTypes.bool,
    fetchingMore: PropTypes.bool,
    defaultItemSize: PropTypes.number,
};

export default InfiniteScrollBody;
