import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import {
    Border,
    LoginContainer,
    FormContent,
    LinkFooter,
} from './styled-components';
import {
    Button,
    BUTTON_TYPES,
    ButtonToolbar,
    EmailInput,
} from '@mspecs/ui-components';
import { useTranslate } from '@mspecs/shared-utils';
import { increasedInputFontSize } from '@mspecs/shared-utils';
import { displayError } from '../notifications/services';
const ForgotPassword = props => {
    const { resetPassword, toggleForgotPassword, emailSentFail } = props;
    const { t } = useTranslate();

    useEffect(() => {
        if (emailSentFail) {
            displayError('FORGOT_PASSWORD_ERROR', 5000);
            return;
        }
    });

    return (
        <LoginContainer
            headline={t('FORGOT_PASSWORD')}
            infoText={t('ENTER_YOUR_EMAIL_ADDRESS_TO_RESET_PASSWORD')}
        >
            <Global styles={increasedInputFontSize} />
            <Border />
            <Form
                onSubmit={resetPassword}
                render={({ handleSubmit, invalid, submitting }) => (
                    <FormContent onSubmit={handleSubmit}>
                        <EmailInput
                            name="email"
                            label="EMAIL"
                            autoComplete="email"
                            required
                            isFormField
                            hideRequiredHint
                            maxLength={80}
                            stackedLabel
                            autoFocus
                        />
                        <ButtonToolbar>
                            <Button
                                label="SEND"
                                type="submit"
                                buttonType={BUTTON_TYPES.PRIMARY}
                                disabled={invalid || submitting}
                                large
                            />
                        </ButtonToolbar>
                    </FormContent>
                )}
            />
            <LinkFooter back={toggleForgotPassword} contactSupport />
        </LoginContainer>
    );
};

ForgotPassword.propTypes = {
    resetPassword: PropTypes.func,
    toggleForgotPassword: PropTypes.func,
    emailSentFail: PropTypes.bool,
};

export default ForgotPassword;
