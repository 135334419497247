import React, { FC, useCallback } from 'react';
import {
    formatValue,
    getUnformattedValue,
    useTranslate,
} from '@mspecs/shared-utils';

import {
    InputFieldWrapper,
    InputLabel,
    InputWrapper,
    Input,
    Suffix,
    InputHelpTextWrapper,
    ErrorIconWrapper,
    LengthText,
    SubLabelText,
    Popover,
    StyledHoverContent,
    IconWrapper,
} from './input-field-component.styles';
import { useTheme } from '@emotion/react';
import { ITheme } from '../../../types';

type MaskOption = 'money' | 'zip' | 'number' | 'year' | undefined;

export interface InputFieldProps {
    invalid?: boolean;
    errorMessage?: string;
    value?: string;
    label?: string;
    subLabel?: string;
    placeholder?: string;
    suffix?: string;
    maxLength?: number;
    required?: boolean;
    useDBTranslation?: boolean;
    mask?: MaskOption;
    saved?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    inputWidth?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    type?: 'text' | 'number' | 'password';
    suffixIcon?: React.ReactNode;
    hideCharacterCount?: boolean;
}

export const Label: FC<InputFieldProps> = ({
    label,
    required,
    useDBTranslation,
    disabled,
}) => {
    const { t } = useTranslate({ useDBTranslation });
    return (
        <InputLabel disabled={disabled}>
            {t(label, { postProcess: 'capitalize' })}
            {required && '*'}
        </InputLabel>
    );
};

const ErrorIcon: FC<{ hasSuffix?: boolean }> = ({ hasSuffix }) => (
    <ErrorIconWrapper hasSuffix={hasSuffix} />
);

export const CharacterCount: FC<{
    value?: string;
    disabled?: boolean;
    maxLength: number;
    mask?: MaskOption;
}> = ({ value = '0', maxLength, mask, disabled }) => (
    <LengthText
        disabled={disabled}
        isOverdrawn={String(value ?? '').length > maxLength}
    >
        {getUnformattedValue(value ?? '', mask)?.length}/{maxLength}
    </LengthText>
);

const InputField: FC<InputFieldProps> = ({
    invalid,
    errorMessage,
    label,
    subLabel,
    suffix,
    maxLength,
    required,
    useDBTranslation,
    mask,
    saved,
    onChange,
    value,
    inputWidth,
    inputRef,
    autoFocus,
    disabled,
    readOnly,
    placeholder,
    suffixIcon,
    hideCharacterCount,
    ...props
}) => {
    const { t } = useTranslate();
    const theme = useTheme() as ITheme;

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const formattedValue = formatValue(e.target.value, mask);
            e.target.value = formattedValue;
            onChange?.(e);
        },
        [mask, onChange]
    );

    const showSubLabel = !!subLabel;

    const showMaxLength =
        !hideCharacterCount &&
        maxLength &&
        (maxLength < 5 || String(value || '').length > maxLength * 0.8);

    return (
        <InputFieldWrapper>
            {label && (
                <Label
                    label={label}
                    required={required}
                    useDBTranslation={useDBTranslation}
                    disabled={disabled || readOnly}
                />
            )}
            <InputWrapper inputWidth={inputWidth}>
                <Input
                    {...props}
                    required={required}
                    isValid={!invalid}
                    saved={saved}
                    value={formatValue(value ?? '', mask)}
                    onChange={handleChange}
                    suffix={suffix}
                    ref={inputRef}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    readOnly={readOnly}
                    placeholder={t(placeholder)}
                />
                {invalid && (
                    <Popover
                        content={
                            <StyledHoverContent>
                                {t(errorMessage)}
                            </StyledHoverContent>
                        }
                        backgroundColor={theme.colors.contrastBgPrimary}
                        placement="bottom"
                    >
                        <span>
                            <ErrorIcon hasSuffix={!!suffix || !!suffixIcon} />
                        </span>
                    </Popover>
                )}
                {!!suffixIcon && <IconWrapper>{suffixIcon}</IconWrapper>}
                {!!suffix && (
                    <Suffix>
                        {typeof suffix === 'string' ? t(suffix) : suffix}
                    </Suffix>
                )}
                {showMaxLength && (
                    <InputHelpTextWrapper>
                        <CharacterCount
                            value={value}
                            maxLength={maxLength}
                            mask={mask}
                            disabled={disabled || readOnly}
                        />
                    </InputHelpTextWrapper>
                )}
            </InputWrapper>
            {showSubLabel && (
                <InputHelpTextWrapper>
                    <SubLabelText
                        disabled={disabled || readOnly}
                        invalid={invalid}
                    >
                        {t(subLabel)}
                    </SubLabelText>
                </InputHelpTextWrapper>
            )}
        </InputFieldWrapper>
    );
};

export default InputField;
