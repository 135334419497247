import React from 'react';
import { InputField } from '.';
import { FormInputField } from '../form';

const ssnPattern = /^(\d{6}|\d{8})[-+]?\d{4}$/;

const SSNInput = ({ isFormField, ...props }) => {
    const Input = isFormField ? FormInputField : InputField;
    <Input
        name="ssn"
        validationPttrn={ssnPattern}
        errorMessage="VALIDATION_ERROR_SSN"
        {...props}
    />;
};

export default SSNInput;
