import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose, branch } from 'recompose';
import styled from '@emotion/styled';
import { ValueContainer } from './styled-components';
import { MspxSelect as Select } from './select-component';
import {
    withInputValidation,
    withFinalFormField,
    withLabel,
    withValidationWrapper,
} from '../../../../utils/hoc';
import { useTranslate } from '@mspecs/shared-utils';

const MultiSelectContainer = styled.div`
    width: 100%;
    button {
        height: 20px !important;
    }
`;

const MultiSelect = props => {
    const {
        defaultValue,
        disabled,
        hideRequiredHint,
        sort,
        onChange,
        options,
        required,
        search,
        value,
        isOptionDisabled,
        label,
        isExplicitSearchBar,
        placeholder,
    } = props;
    const { t } = useTranslate();

    const onSelectChange = useCallback(
        selected => {
            onChange(selected ? selected.map(x => x.value) : selected);
        },
        [onChange]
    );

    return (
        <MultiSelectContainer>
            <Select
                closeMenuOnSelect={false}
                components={{ ValueContainer }}
                defaultValue={defaultValue}
                hideRequiredHint={hideRequiredHint}
                hideSelectedOptions={false}
                isDisabled={disabled}
                isOptionDisabled={isOptionDisabled}
                isMulti
                sort={sort}
                onChange={onSelectChange}
                options={options}
                required={required}
                search={search}
                isExplicitSearchBar={isExplicitSearchBar}
                value={!value || Array.isArray(value) ? value : [value]}
                t={t}
                label={label}
                placeholder={placeholder}
            />
        </MultiSelectContainer>
    );
};

MultiSelect.propTypes = {
    //defaultValue in Final form: declare an array outside the form to avoid infinite render loops
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    disabled: PropTypes.bool,
    hideRequiredHint: PropTypes.bool,
    onChange: PropTypes.func,
    isOptionDisabled: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.object,
            PropTypes.shape({
                value: PropTypes.any.isRequired,
                label: PropTypes.any.isRequired,
            }),
        ])
    ),
    required: PropTypes.bool,
    search: PropTypes.bool,
    isExplicitSearchBar: PropTypes.bool,
    t: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string,
};

export default compose(
    withValidationWrapper,
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    ),
    withLabel
)(MultiSelect);
