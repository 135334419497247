import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';

import { useTranslate } from '@mspecs/shared-utils';
import TextWithHighlightedPart from './text-with-highlighted-part-component';
import LoadingSpinner from '../../../../animations/loading-spinner-component';
import {
    BodyTextRegular,
    HighlightedBodyText,
    SubTextMedium,
} from '../../../../typograph/typograph-components';

const SuggestionContent = styled(Box)`
    max-height: 300px;
    overflow-y: auto;
`;
export const SuggestionText = styled(HighlightedBodyText)`
    padding: ${({ theme: { spacing } }) =>
        `${spacing.default} ${spacing.small}`};
    cursor: pointer;
    &:hover {
        background: ${({ theme }) => theme.colors.primaryColor10};
        border-radius: ${({ theme }) => theme.borderRadius.default};
    }
    font-weight: 400;
`;
const SuggestionResultText = styled(SubTextMedium)`
    padding: ${({ theme: { spacing } }) => `0 ${spacing.small}`};
`;

const SuggestionsWrapper = styled(Box)``;
const SpinnerWrapper = styled(Flex)`
    margin-top: ${({ theme }) => theme.spacing.default};
    align-items: center;
    padding: ${({ theme }) => theme.spacing.small};
`;
const LoadingText = styled(BodyTextRegular)`
    padding-left: ${({ theme }) => theme.spacing.small};
`;
const Content = styled(Box)`
    padding: ${({ theme }) => theme.spacing.small};
`;

export const SearchInputSuggestions = ({
    isLoading,
    searchResult,
    selectedSuggestion,
    children,
    onSuggestionClick,
    searchString,
    ...props
}) => {
    const { t } = useTranslate();

    return (
        <SuggestionContent {...props}>
            {isLoading && (
                <Content>
                    <SpinnerWrapper>
                        <LoadingSpinner />
                        <LoadingText>{t('LOADING')}</LoadingText>
                    </SpinnerWrapper>
                </Content>
            )}
            {searchResult.length > 0 && !selectedSuggestion && !isLoading && (
                <Content>
                    {children}
                    <SuggestionResultText>{`${t('SEARCH_RESULTS!')}: ${t(
                        'GENERIC_COUNT',
                        {
                            count: searchResult.length,
                        }
                    )}`}</SuggestionResultText>
                    <SuggestionsWrapper>
                        {searchResult.map(suggestion => (
                            <Box
                                key={suggestion.value}
                                onClick={() => onSuggestionClick(suggestion)}
                            >
                                <SuggestionText>
                                    <TextWithHighlightedPart
                                        text={suggestion.label}
                                        searchString={searchString}
                                    />
                                </SuggestionText>
                            </Box>
                        ))}
                    </SuggestionsWrapper>
                </Content>
            )}
        </SuggestionContent>
    );
};
SearchInputSuggestions.propTypes = {
    children: PropTypes.any,
    isLoading: PropTypes.bool,
    searchResult: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })
    ),
    selectedSuggestion: PropTypes.string,
    onSuggestionClick: PropTypes.func,
    searchString: PropTypes.string,
};

export default SearchInputSuggestions;
