import { combineReducers } from 'redux';
import { SESSION_USER_LOGOUT } from '../actions/session-actions';
import { communicate } from './communicate-reducers';
import communication from './communication-reducers';
import { emails } from './email-reducers';
import { session } from './session-reducers';
import page from './page-reducer';
import deal from './deal-reducers';
import oauth from './oauth-reducers';
import signatures from './signature-reducers';
import contacts from './contact-reducers';
import entities from './entity-reducers';
import dealWiz from './deal-wiz-reducers';
import schemas from './schema-reducers';
import customerPage from './customer-page-reducer';
import requests from './request-reducers';
import memoPad from './memoPad-reducers';
import objectDescription from './object-description-v2-reducers';
import externalMarketingPlaces from './external-marketing-place/external-marketing-place-reducers';
import externalMarketingPlacesData from './external-marketing-place/external-marketing-place-data-reducers';
import countries from './country-reducers';
import printModal from './print-modal-reducers';
import esignModal from './esignature/esign-modal-reducers';
import esignatureSettings from './esignature/esignature-settings-reducers';
import publishingProviderService from './publishing/publish-reducer';
import tambur from './tambur-reducer';
import providerServices from './provider/provider-services-reducers';
import translation from './translation-reducer';

/*
    NOTE: The list perserves the order in the redux devtools,
    New state props should be placed over 'entities' and under deal/session/oauth.
    I.e important stuff on top
*/
const appReducer = combineReducers({
    page,
    deal,
    session,
    oauth,
    requests,
    emails,
    // should be moved to Entities
    contacts,
    // should be moved to Entities
    signatures,
    communicate,
    dealWiz,
    customerPage,
    memoPad,
    schemas,
    objectDescription,
    externalMarketingPlaces,
    externalMarketingPlacesData,
    countries,
    printModal,
    /** Entities should be the property in which raw data is stored normalized.
     *  Other state properties should refer to data in entities via ids
     */
    entities,
    communication,
    esignModal,
    esignatureSettings,
    publishingProviderService,
    tambur,
    providerServices,
    translation,
});

// Root Reducers to clear the entire redux store after logout
export default function rootReducer(state, action) {
    if (action.type === SESSION_USER_LOGOUT) {
        state = undefined;
    }

    return appReducer(state, action);
}
