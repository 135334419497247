import { format } from 'date-fns';
import { sv, enGB } from 'date-fns/locale';
import { currentLanguage } from './services/i18n';

const locales = {
    sv,
    en: enGB,
};

export const DATE_FORMATS = {
    MONTH_NAME_SHORT: 'd MMM',
    MONTH_NAME_SHORT_WITH_TIME: 'd MMM HH:mm',
    MONTH_NAME_SHORT_WITH_YEAR: 'd MMM yyyy',
    STANDARD_DATE: 'yyyy-MM-dd',
    TIME: 'HH:mm',
};

export const sortDate = (arr, field, descending = false) => {
    if (descending) {
        return arr.sort((a, b) => new Date(b[field]) - new Date(a[field]));
    }
    // Ascending
    return arr.sort((a, b) => new Date(a[field]) - new Date(b[field]));
};

export const formatDate = (date, includeTime = false) =>
    date
        ? format(
              new Date(date),
              `yyyy-MM-dd${includeTime ? ` ${DATE_FORMATS.TIME}` : ''}`
          )
        : '';

export const formatLocaleDate = (date, formatString, options = {}) =>
    format(date, formatString, {
        ...options,
        locale: locales[currentLanguage()],
    });

export const formatTime = date => format(new Date(date), DATE_FORMATS.TIME);
