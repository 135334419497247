import {
    primaryColor20,
    primaryColor,
    pending20,
    gray100,
    green20,
    green60,
} from '@mspecs/shared-utils';

export const getFormSettings = name => {
    return {
        translation: FORM_TRANSLATION[name],
        compare: FORM_COMPARE[name],
        schema: FORM_SCHEMAS[name],
        compareKey: COMPARE_KEY[name],
        titleKey: TITLE_KEY[name],
        hidden: HIDDEN_COMPARE[name],
    };
};

export const TITLE_KEY = {
    capitalGainCalculation: '',
    capitalGainCalculationImprovements: 'description',
    capitalGainCalculationSellers: (formData, sellers) => {
        const contact = sellers[formData['sellerId']];
        if (!contact) return '';
        return `${contact.firstName} ${contact.lastName}`;
    },
    amounts: 'title',
    mainEstate: '',
};

export const FORM_STATUS = {
    OPEN: 'ENUM_FORM_INVITATIONS_FORM_STATUS_OPEN',
    CHANGED: 'ENUM_FORM_INVITATIONS_FORM_STATUS_CHANGED',
    SUBMITTED: 'ENUM_FORM_INVITATIONS_FORM_STATUS_SUBMITTED',
    LOCKED: 'ENUM_FORM_INVITATIONS_FORM_STATUS_LOCKED',
    MERGED: 'ENUM_FORM_INVITATIONS_FORM_STATUS_MERGED',
    NOT_SENT: 'ENUM_FORM_INVITATIONS_FORM_STATUS_NOT_SENT',
    WILL_NOT_BE_SENT: 'ENUM_FORM_INVITATIONS_FORM_STATUS_WILL_NOT_BE_SENT',
};

export const FORM_NAME = {
    FORM_CAPITAL_GAIN_CALCULATION: 'capitalGainCalculation',
    FORM_ESTATE_OPERATING_COSTS: 'estateOperatingCosts',
};

export const COMPARE_KEY = {
    capitalGainCalculation: '',
    capitalGainCalculationImprovements: 'description',
    capitalGainCalculationSellers: 'sellerId',
    amounts: 'title',
    mainEstate: '',
};

export const HIDDEN_COMPARE = {
    capitalGainCalculation: ['id'],
    capitalGainCalculationImprovements: [
        'id',
        'capitalGainCalculationId',
        'amount',
        'updatedDate',
    ],
    capitalGainCalculationSellers: [
        'id',
        'sellerId',
        'propertyUsedAsPermanentOrHolidayLivingFullPeriod',
        'deferral',
        'capitalGainCalculationId',
        'updatedDate',
    ],
    amounts: ['id'],
    mainEstate: ['id'],
};

export const FORM_TRANSLATION = {
    capitalGainCalculation: 'CAPITAL_GAIN',
    capitalGainCalculationImprovements: 'CAPITAL_GAIN_IMPROVEMENTS',
    capitalGainCalculationSellers: 'CAPITAL_GAIN_SELLERS',
    amounts: 'ESTATE_OTHER_OPERATING_COSTS',
    mainEstate: 'OBJECT_DESCRIPTION_OPERATING_COST',
};

export const FORM_OBJECT_KEYS = {
    FORM_ESTATE_OPERATING_COSTS: ['mainEstate'],
    FORM_CAPITAL_GAIN_CALCULATION: ['capitalGainCalculation'],
};

export const FORM_COMPARE = {
    capitalGainCalculation: false,
    capitalGainCalculationImprovements: true,
    capitalGainCalculationSellers: true,
    amounts: true,
    mainEstate: false,
};

export const FORM_SCHEMAS = {
    capitalGainCalculation: 'capitalGainCalculations',
    capitalGainCalculationImprovements: 'capitalGainCalculationImprovements',
    capitalGainCalculationSellers: 'capitalGainCalculationSellers',
    amounts: 'amounts',
    mainEstate: 'estates',
};

export const TRANSLATIONS = {
    UPDATED: 'CUSTOMERPAGE_UPDATED', // Uppdaterad
    VISIBLE: 'CUSTOMERPAGE_VISIBLE', // Synlig
    SENT: 'CUSTOMERPAGE_SENT', // Formuläret är skickat till säljare men har inte besvarat det ännu.
    MARK_AS_MERGED: 'CUSTOMERPAGE_MARK_AS_MERGED', // Markera som klar
    SELECT_OBJECT_TO_COMPARE: 'CUSTOMERPAGE_SELECT_OBJECT_TO_COMPARE', // Välj objekt att jämnföra till vänster
    CUSTOMERPAGE: 'CUSTOMERPAGE_CUSTOMERPAGE', // Kundens sida
    FORM_SUBMITTED: 'CUSTOMERPAGE_FORM_SUBMITTED', // Inskickad formulärdata
    FORMS_HELPBOX: 'CUSTOMERPAGE_FORMS_HELPBOX', // Klicka på knappen Importera för att inhämtning av objektinformation.
    FORMS_HELPBOX2: 'CUSTOMERPAGE_FORMS_HELPBOX_2', // Klicka på knappen med hänglåset för respektive sida för att låsa / låsa upp sidans formulär.
    HELPBOX_TITLE: 'CUSTOMERPAGE_HELPBOX_TITLE', // Ny information har inkommit
    DIFF_CHOOSE: 'CUSTOMERPAGE_DIFF_CHOOSE', // Välj objekt att jämnföra till vänster
    SUBMITTED: 'CUSTOMERPAGE_SUBMITTED', // Inskickad
    SAVED: 'CUSTOMERPAGE_SAVED', // Sparad
    IMPORTED: 'CUSTOMERPAGE_IMPORTED', // Formulär importerat
    NO_DATA: 'CUSTOMERPAGE_NO_DATA', // Ingen data att visa / No data to show
    FORM_MENU: 'CUSTOMERPAGE_FORM_MENU',
    FORM_HEADER: 'CUSTOMERPAGE_FORM_HEADER',
    CATEGORY: 'CUSTOMERPAGE_CATEGORY',
    STATUS: 'CUSTOMERPAGE_STATUS',
    DELETE_TITLE: 'IMPORT_TOAST_DELETE_TITLE',
    IMPORT_TITLE: 'IMPORT_TOAST_IMPORT_TITLE',
    DELETE_BODY: 'IMPORT_TOAST_DELETE_BODY',
    IMPORT_BODY: 'IMPORT_TOAST_IMPORT_BODY',
};

export const COLOR_MAP_TABLE = {
    [FORM_STATUS.OPEN]: pending20,
    [FORM_STATUS.CHANGED]: pending20,
    [FORM_STATUS.SUBMITTED]: primaryColor20,
    [FORM_STATUS.MERGED]: green20,
    [FORM_STATUS.LOCKED]: pending20,
    [FORM_STATUS.NOT_SENT]: gray100,
};

export const COLOR_MAP_MENU = {
    [FORM_STATUS.MERGED]: green60,
    [FORM_STATUS.CHANGED]: primaryColor,
    [FORM_STATUS.SUBMITTED]: primaryColor,
    [FORM_STATUS.OPEN]: primaryColor,
    [FORM_STATUS.LOCKED]: primaryColor,
};

export const HIDE_FROM_MERGED_VIEW = [
    'FORM_TYPE_FILE_DOWNLOAD',
    'FORM_BANK_SETTINGS',
];
export const HIDE_FROM_FORM_LIST = [
    'FORM_TYPE_FILE_DOWNLOAD',
    'FORM_BANK_SETTINGS',
];

export const MERGE_DOCUMENT_TYPE = {
    FILES: 'FILES',
    DEAL_IMAGES: 'DEAL_IMAGES',
};
