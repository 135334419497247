import { useState } from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import { useTranslate } from '@mspecs/shared-utils';
import { TEXT_STYLES } from '../../../typograph/typograph-components';
import LoadingSpinner from '../../../animations/loading-spinner-component';
import InputField from '../input-field-component';

const STYLE_TYPES = {
    OUTLINED: 'outlined',
    FILLED: 'filled',
};

const StyledSearchInput = styled(InputField)`
    padding-left: 34px;
    padding-right: 30px;

    ${({ theme }) => TEXT_STYLES.bodyText(theme)}
    font-size: 16px;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        font-size: 14px;
    }
    &.outlined {
        border-color: ${({ theme }) => theme.colors.borderSecondary};
    }
    &.filled {
        background: ${({ theme }) => theme.colors.bgSecondary};
        &:focus {
            background: ${({ theme }) => theme.colors.bgPrimary};
        }

        &:hover {
            border-color: ${({ theme }) => theme.colors.borderPrimary};
        }
    }
    margin-bottom: 0;
`;

const AbsoluteIcon = styled(FontAwesomeIcon)`
    position: absolute;
    z-index: 2;
    color: ${({ theme }) => theme.colors.textSecondary};
    transition: color ease-in-out 0.15s;
`;

const SearchIcon = styled(AbsoluteIcon)`
    left: 7px;
    top: 7px;
    font-size: 20px;
    pointer-events: none;
`;

const ClearIcon = styled(AbsoluteIcon)`
    right: 0;
    top: 0;
    font-size: 16px;
    cursor: pointer;
    padding: ${({ theme }) => theme.spacing.default};
    &:hover {
        color: ${({ theme }) => theme.colors.textPrimary};
        transform: scale(1.1);
    }
`;

const LoadingIcon = styled(LoadingSpinner)`
    right: ${({ theme }) => theme.spacing.default};
    top: 0;
    position: absolute;
    z-index: 2;
    height: 24px;
    width: 24px;
`;
const SearchWrapper = styled(Flex)`
    position: relative;
    align-items: center;
    width: 100%;

    ${SearchIcon} {
        color: ${({ theme, isFocused }) =>
            isFocused && theme.colors.textPrimary};
    }
    &:hover {
        ${SearchIcon} {
            color: ${({ theme }) => theme.colors.textPrimary};
        }
    }
`;

const SearchInput = ({ placeholder, className, isLoading, ...restProps }) => {
    const { value, onChange, type } = restProps;
    const { t } = useTranslate();

    const [isFocused, setIsFocused] = useState(false);

    const onFocus = () => {
        setIsFocused(true);
        restProps.onFocus?.();
    };

    const onBlur = () => {
        setIsFocused(false);
        restProps.onBlur?.();
    };

    return (
        <SearchWrapper className={className} isFocused={isFocused}>
            <SearchIcon icon={faMagnifyingGlass} size="xl" />
            <StyledSearchInput
                className={type}
                {...restProps}
                placeholder={t(placeholder)}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            {value && onChange && !isLoading && (
                <ClearIcon
                    icon={faXmark}
                    onClick={e => {
                        e.target.value = '';
                        onChange(e);
                    }}
                />
            )}
            {isLoading && <LoadingIcon size="xs" />}
        </SearchWrapper>
    );
};
SearchInput.propTypes = {
    type: PropTypes.oneOf(Object.values(STYLE_TYPES)),
    placeholder: PropTypes.string,
    className: PropTypes.string,
    t: PropTypes.func,
};

SearchInput.defaultProps = {
    type: 'outlined',
};

export default SearchInput;
