import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { useTranslate } from '@mspecs/shared-utils';

const ValueContainer = props => {
    const { children, selectProps } = props;
    const { value } = selectProps;
    const { t } = useTranslate();
    const valueLength = value && value.length;
    let selectedValue;

    if (valueLength > 2) {
        selectedValue = `${valueLength} ${t('SELECTED').toLowerCase()}`;
    } else if (valueLength === 2) {
        selectedValue = value.map(x => x && t(x.label)).join(', ');
    } else {
        selectedValue = children[0];
    }

    return (
        <components.ValueContainer {...props}>
            {selectedValue}
            {children[1]}
        </components.ValueContainer>
    );
};

ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object,
};

export default ValueContainer;
