import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import CheckMarkAndTextButton from '../../button/icon-button/check-mark-and-text-icon-component';
import { useCurrentBreakpoint, useTranslate } from '@mspecs/shared-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddButton } from '../../button';
import { IconButtonToolbar } from '../../button/icon-button-toolbar';
import { MenuPopover } from '../../popovers';

const StyledTopToolbar = styled(Flex)`
    gap: ${({ theme }) => theme.spacing.default};
`;
const TopToolbar = ({ children, ...props }) => {
    return (
        <StyledTopToolbar
            mb="6px"
            alignItems="end"
            flexShrink="0"
            flexDirection={['column', 'row', 'row']}
            {...props}
        >
            {children}
        </StyledTopToolbar>
    );
};
TopToolbar.Filter = styled.div`
    display: none;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        max-width: 164px;
        display: block;
    }
`;
TopToolbar.AddButton = ({ label, ...props }) => {
    const breakpoint = useCurrentBreakpoint();
    const isSmall = ['xs', 's'].includes(breakpoint);
    return (
        <AddButton
            hiddenLabel="ADD_DEAL"
            {...props}
            label={isSmall ? '' : label}
            isRound={isSmall || !label}
        />
    );
};
TopToolbar.SettingsButton = props => (
    <MenuPopover options={props.settingsOptions} placement="bottom-end">
        <div tabIndex={-1}>
            <IconButtonToolbar hiddenLabel="OPEN_SETTINGS" {...props}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
            </IconButtonToolbar>
        </div>
    </MenuPopover>
);

TopToolbar.ResetTableDrag = props => {
    const { t } = useTranslate();
    return (
        <CheckMarkAndTextButton {...props}>
            {t('TABLE_MOVE_COLUMNS_CLOSE_BUTTON_LABEL')}
        </CheckMarkAndTextButton>
    );
};

TopToolbar.propTypes = {
    children: PropTypes.node,
};
TopToolbar.SettingsButton.propTypes = {
    settingsOptions: PropTypes.array,
};

export default TopToolbar;
