import { ac } from '../../../utils/redux-utils';
import {
    notificationUpdateError,
    notificationUpdateSuccess,
} from '~/utils/notification-utils';
import { legacyApi } from '@mspecs/shared-utils';

const CONFIGURATION_NAMES = {
    marketing: 'marketing',
};

export const ACTIONS = {
    EXTERNAL_MARKETING_PLACE_GET_BROKER: 'EXTERNAL_MARKETING_PLACE_GET_BROKER',
    EXTERNAL_MARKETING_PLACE_BROKER_FOUND:
        'EXTERNAL_MARKETING_PLACE_BROKER_FOUND',
    EXTERNAL_MARKETING_PLACE_BROKER_NOT_FOUND:
        'EXTERNAL_MARKETING_PLACE_BROKER_NOT_FOUND',

    EXTERNAL_MARKETING_PLACE_CREATE_BROKER:
        'EXTERNAL_MARKETING_PLACE_CREATE_BROKER',
    EXTERNAL_MARKETING_PLACE_BROKER_CREATED:
        'EXTERNAL_MARKETING_PLACE_BROKER_CREATED',

    EXTERNAL_MARKETING_PLACE_GET_MARKETING_PLACES:
        'EXTERNAL_MARKETING_PLACE_GET_MARKETING_PLACES',
    EXTERNAL_MARKETING_PLACE_GET_MARKETING_PLACES_SUCCESS:
        'EXTERNAL_MARKETING_PLACE_GET_MARKETING_PLACES_SUCCESS',

    EXTERNAL_MARKETING_PLACE_UPDATE_MARKETING_PLACE:
        'EXTERNAL_MARKETING_PLACE_UPDATE_MARKETING_PLACE',
    EXTERNAL_MARKETING_PLACE_UPDATE_MARKETING_PLACE_SUCCESS:
        'EXTERNAL_MARKETING_PLACE_UPDATE_MARKETING_PLACE_SUCCESS',

    EXTERNAL_MARKETING_PLACE_ERROR: 'EXTERNAL_MARKETING_PLACE_ERROR',
};

export const LICENSE_MARKETING_PLACE_CONSTANT = 'license';

const getBrokerAction = () => ac(ACTIONS.EXTERNAL_MARKETING_PLACE_GET_BROKER);
const brokerFoundAction = ({ organizationUnitId }) =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_BROKER_FOUND, { organizationUnitId });
const brokerNotFoundAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_BROKER_NOT_FOUND);
const createBrokerAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_CREATE_BROKER);
const brokerCreatedAction = ({ organizationUnitId }) =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_BROKER_CREATED, { organizationUnitId });

const getMarketingPlacesAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_GET_MARKETING_PLACES);
const getMarketingPlacesSuccessAction = ({
    marketingPlaces,
    organizationUnitId,
}) =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_GET_MARKETING_PLACES_SUCCESS, {
        marketingPlaces,
        organizationUnitId,
    });

const updateMarketingPlaceAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_UPDATE_MARKETING_PLACE);

const updateMarketingPlaceActionSuccess = ({
    marketingPlace,
    data,
    organizationUnitId,
}) =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_UPDATE_MARKETING_PLACE_SUCCESS, {
        marketingPlace,
        data,
        organizationUnitId,
    });

const handleError = error => ac(ACTIONS.EXTERNAL_MARKETING_PLACE_ERROR, error);

export function getBroker(organizationUnitId) {
    return async (dispatch, getState) => {
        const { externalMarketingPlaces } = getState();
        const orgUnitId =
            organizationUnitId || LICENSE_MARKETING_PLACE_CONSTANT;
        if (externalMarketingPlaces.brokerFirms[orgUnitId]) {
            return;
        }
        try {
            dispatch(getBrokerAction());
            await legacyApi(
                `/v2/publishing/broker/${organizationUnitId || ''}`
            ).get();
            dispatch(brokerFoundAction({ organizationUnitId }));
        } catch (e) {
            if (
                e.systemConstant === 'ERROR_PUBLISHING_PROXY_BROKER_NOT_FOUND'
            ) {
                dispatch(brokerNotFoundAction());
                throw e;
            } else {
                dispatch(handleError(e));
            }
        }
    };
}

export function createBroker(organizationUnitId) {
    return async dispatch => {
        try {
            dispatch(createBrokerAction());
            await legacyApi(
                `/v2/publishing/broker/${organizationUnitId || ''}`
            ).post();
            dispatch(brokerCreatedAction({ organizationUnitId }));
        } catch (e) {
            dispatch(handleError(e));
        }
    };
}

export function getMarketingPlaces(organizationUnitId) {
    return async (dispatch, getState) => {
        const { externalMarketingPlaces = {} } = getState();
        const orgUnitId =
            organizationUnitId || LICENSE_MARKETING_PLACE_CONSTANT;
        if (
            externalMarketingPlaces.marketingPlaces &&
            externalMarketingPlaces.marketingPlaces[orgUnitId]
        ) {
            return externalMarketingPlaces.marketingPlaces[orgUnitId];
        }
        try {
            dispatch(getMarketingPlacesAction());
            const marketingPlaces = await legacyApi(
                `/v2/publishing/marketingPlaces/${organizationUnitId || ''}`
            ).get();
            dispatch(
                getMarketingPlacesSuccessAction({
                    marketingPlaces,
                    organizationUnitId: orgUnitId,
                })
            );
            return marketingPlaces;
        } catch (e) {
            if (
                e.systemConstant === 'ERROR_PUBLISHING_PROXY_BROKER_NOT_FOUND'
            ) {
                dispatch(brokerNotFoundAction());
            } else {
                dispatch(handleError(e));
            }
            throw e;
        }
    };
}

export function updateMarketingPlace(marketingPlace, data, organizationUnitId) {
    return async dispatch => {
        try {
            dispatch(updateMarketingPlaceAction());
            const updatedData = await legacyApi(
                `/v2/publishing/marketingPlace/${marketingPlace}/${
                    organizationUnitId || ''
                }`
            ).put(data);
            notificationUpdateSuccess(dispatch);
            dispatch(
                updateMarketingPlaceActionSuccess({
                    marketingPlace,
                    data: updatedData,
                    organizationUnitId,
                })
            );
        } catch (e) {
            notificationUpdateError(dispatch);
            dispatch(handleError(e));
        }
    };
}
