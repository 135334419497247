import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';

import { HighlightedBodyTextBold } from '../typograph/typograph-components';
import { css } from '@emotion/react';

const mobileAnimationVars = css`
    --slide-in-start: translateY(100%);
    --slide-in-end: translateY(0);
    --slide-out-start: translateY(0);
    --slide-out-end: translateY(100%);
`;

const tabletAnimationVars = css`
    --slide-in-start: translateY(100%) translateX(-50%);
    --slide-in-end: translateY(0) translateX(-50%);
    --slide-out-start: translateY(0) translateX(-50%);
    --slide-out-end: translateY(100%) translateX(-50%);
`;

const slideAnimation = css`
    @keyframes slideIn {
        0% {
            transform: var(--slide-in-start);
        }
        100% {
            transform: var(--slide-in-end);
        }
    }

    @keyframes slideOut {
        0% {
            transform: var(--slide-out-start);
        }
        100% {
            transform: var(--slide-out-end);
        }
    }
`;

export const OptionsMenu = styled.div<{
    bottomPosition: number;
    isClosing: boolean;
}>`
    position: fixed;
    bottom: ${({ bottomPosition }) => `${bottomPosition}px`};
    left: 0;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: ${({ theme }) => `${theme.spacing.large}`};
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    ${slideAnimation};
    animation: ${({ isClosing }) => (isClosing ? 'slideOut' : 'slideIn')} 0.3s
        ease-in-out;
    width: 100%;
    ${mobileAnimationVars};

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        ${tabletAnimationVars};
        max-width: 600px;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
    }
`;

export const DragIndicator = styled.div`
    width: 50px;
    height: 8px;
    background-color: ${props => props.theme.colors.gray80};
    border-radius: ${({ theme }) => `${theme.borderRadius.default}`};
    margin: 0 auto;
    cursor: grab;
`;

export const OptionsMenuHeader = styled(HighlightedBodyTextBold)`
    padding: ${({ theme }) =>
        `${theme.spacing.default} ${theme.spacing.large}`};
`;

export const OptionsMenuContent = styled(Flex)``;
