import {
    SESSION_USER_LOGIN,
    SESSION_USER_LOGOUT,
    SESSION_USER_FAIL,
    SESSION_SET_USER_COOKIE_CONSENT,
    SESSION_TOO_MANY_LOGIN_ATTEMPTS,
    RESET_USER_LOGIN_ERROR,
    FETCH_SESSION_REQUEST,
    FETCH_SESSION_SUCCESS,
    FETCH_SESSION_ERROR,
    FETCH_SESSION_ADMIN_ORG_UNIT_SUCCESS,
    FETCH_SESSION_ORG_UNIT_SUCCESS,
} from '../actions/session-actions';

const initialState = {
    id: '',
    locale: '',
    contact: {},
    cookie: { analyticsConsentDate: null, cookieConsentDate: null },
    google: {},
    organization: {},
    accessRights: {},
    adminOrgUnits: [],
    organizationUnits: {},
    isAdmin: false,
    isOrgUnitAdmin: false,
    isSigningIn: false,
    isLoggedIn: false,
    loginFail: false,
    isFetchingSession: false,
    isSessionFetched: false,
    tooManyLoginAttempts: false,
};

export const session = (state = initialState, action) => {
    switch (action.type) {
        case SESSION_USER_FAIL:
        case SESSION_TOO_MANY_LOGIN_ATTEMPTS:
            return {
                ...state,
                isLoggedIn: false,
                loginFail: true,
                isSigningIn: false,
                tooManyLoginAttempts:
                    action.type === SESSION_TOO_MANY_LOGIN_ATTEMPTS,
            };
        case RESET_USER_LOGIN_ERROR:
            return {
                ...state,
                loginFail: false,
                tooManyLoginAttempts: false,
            };
        case SESSION_USER_LOGIN:
            return {
                ...state,
                isLoggedIn: true,
                isSigningIn: true,
            };
        case SESSION_USER_LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
            };
        case SESSION_SET_USER_COOKIE_CONSENT:
            return {
                ...state,
                cookie: { ...action.payload },
            };
        case FETCH_SESSION_REQUEST:
            return {
                ...initialState,
                isSessionFetched: false,
                isFetchingSession: true,
            };
        case FETCH_SESSION_SUCCESS: {
            const { organizationUnits, ...data } = action.payload;
            const newState = {
                ...state,
                ...data,
                organizationUnitsAccess: organizationUnits,
                isFetchingSession: false,
                isSessionFetched: true,
                accessRights: action.payload.accessRights.reduce((acc, x) => {
                    acc[x] = true;
                    return acc;
                }, {}),
            };
            return {
                ...newState,
                isAdmin:
                    newState.accessRights.ACCESS_RIGHT_ADMIN ||
                    newState.accessRights.ACCESS_RIGHT_SYSTEM_ADMIN,
                isOrgUnitAdmin:
                    !!newState.accessRights
                        .ACCESS_RIGHT_ORGANIZATION_UNIT_ADMIN,
                isBrokerOwnDeals:
                    !!newState.accessRights.ACCESS_RIGHT_BROKER_OWN_DEALS,
            };
        }
        case FETCH_SESSION_ERROR:
            return {
                ...initialState,
                isSessionFetched: true,
            };
        case FETCH_SESSION_ADMIN_ORG_UNIT_SUCCESS:
            return {
                ...state,
                adminOrgUnits: action.payload,
            };
        case FETCH_SESSION_ORG_UNIT_SUCCESS:
            return {
                ...state,
                organizationUnits: action.payload,
            };
        default:
            return state;
    }
};
