import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from '@mspecs/shared-utils';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import BigBackButton from '../../button/icon-button/back-icon-button-big';
import { BodyTextRegular } from '../../typograph/typograph-components';
import { isMathchingDynamicPath, MenuItemPropType } from '../utils';

const Container = styled(Flex)`
    flex-direction: column;
`;
const IconWrapper = styled(Flex)`
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;
`;

const StyledMobileMenuItem = styled(Flex)`
    border-bottom: 1px solid ${({ theme }) => theme.colors.bgSecondary140};
    height: 44px;
    align-items: center;
    justify-content: space-between;
`;
const LabelText = styled(BodyTextRegular)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 24px;
`;

const ItemWrapper = styled(Flex)`
    gap: 5px;
`;
const MenuItem = ({ label, icon, ...props }) => {
    return (
        <StyledMobileMenuItem {...props}>
            <ItemWrapper>
                <IconWrapper>{icon}</IconWrapper>
                <LabelText>{label}</LabelText>
            </ItemWrapper>
            <IconWrapper>
                <FontAwesomeIcon icon={faChevronRight} />
            </IconWrapper>
        </StyledMobileMenuItem>
    );
};

const generateInitialNavStack = (items, currentRoute) => {
    const outerParent = items.find(item =>
        item.subItems.some(sub => currentRoute.endsWith(sub.path))
    );
    const currentItem = outerParent?.subItems.find(sub =>
        currentRoute.endsWith(sub.path)
    );
    return [outerParent, currentItem].filter(Boolean);
};

function MobileFullpageNavigation({ items, backRoute, routes = [], children }) {
    const { t } = useTranslate();
    const navigate = useNavigate();
    const location = useLocation();
    const [navStack, setNavStack] = useState(
        generateInitialNavStack(items, location.pathname)
    );

    const menuItems = useMemo(
        () =>
            !navStack.length
                ? items
                : navStack[navStack.length - 1].subItems ?? [],
        [navStack, items]
    );

    const isInChildRoute = useMemo(() => {
        const flatItems = items
            .reduce((acc, item) => {
                const subItems = item.subItems || [];
                const thirdLevelItems = item.subItems.reduce(
                    (subAcc, subItem) => [
                        ...subAcc,
                        ...(subItem?.subItems ?? []),
                    ],
                    []
                );
                return [
                    ...acc,
                    item.path,
                    ...[...subItems, ...thirdLevelItems].map(sub => sub.path),
                ];
            }, [])
            .filter(Boolean);
        return flatItems.some(
            path =>
                location.pathname.endsWith(path) ||
                isMathchingDynamicPath(location.pathname, path)
        );
    }, [items, location.pathname]);

    const handleBack = e => {
        if (!navStack.length) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        navigate('');
        setNavStack(prev => prev.slice(0, -1));
    };

    const onMenuItemClick = item => () => {
        if (item.path) {
            navigate(item.path);
        }
        setNavStack(prev => [...prev, item]);
    };

    return (
        <Container>
            <BigBackButton
                label={t('BACK')}
                onClick={handleBack}
                to={backRoute}
            />
            {children}
            {!isInChildRoute &&
                menuItems.map(item => (
                    <MenuItem
                        key={item.label}
                        {...item}
                        label={t(item.label)}
                        onClick={onMenuItemClick(item)}
                    />
                ))}
            <Routes>
                {routes.map(item => (
                    <Route
                        key={item.path}
                        path={item.path}
                        element={item.element}
                    />
                ))}
            </Routes>
        </Container>
    );
}

export default MobileFullpageNavigation;

MobileFullpageNavigation.propTypes = {
    backRoute: PropTypes.string,
    items: PropTypes.arrayOf(MenuItemPropType),
    children: PropTypes.node,
    routes: PropTypes.arrayOf(
        PropTypes.shape({ path: PropTypes.string, element: PropTypes.node })
    ),
};
