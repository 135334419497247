import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainItems from './content-menu-items-component';
import SubItems from './content-menu-sub-items-component';
import { useIsMobile } from '@mspecs/shared-utils';
import { useTranslate } from '@mspecs/shared-utils';
import BigBackButton from '../../button/icon-button/back-icon-button-big';
import NavigationHeadline from '../navigation-headline-component';

const Menu = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: ${({ theme }) => theme.colors.bgSecondary};
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 16px;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        padding: 62px 5px 0;
        width: 215px;
        min-width: 215px;
        background: white;
        border-right: solid 1px ${({ theme }) => theme.colors.borderPrimary};
        box-shadow: ${({ theme }) => theme.boxShadows.small};
    }
`;

const MenuContainer = styled.div`
    padding: 0 0 10px 0;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        padding: 0 0 20px 0;
    }
`;

const SubMenuContainer = styled.div`
    overflow-y: auto;
    padding: 0 0 10px 0;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        padding: 0 0 20px 0;
    }
`;

export const ToggleMenuItem = styled.div`
    display: flex;
    cursor: pointer;
    padding: 10px;
    font-size: ${({ theme }) => theme.fontSizeNormal};
    border-radius: ${({ theme }) => theme.borderRadius.default};
    background: ${({ theme }) => theme.colors.primaryColor10};
    margin-bottom: 5px;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primaryColor};
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        border-radius: ${({ theme }) => theme.borderRadius.medium};
        background: ${({ theme }) => theme.colors.primaryColor5};
        height: auto;
    }
`;

export const StyledToggleIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.primaryColor};
    transform: rotate(${({ toggled }) => (toggled === 'true' ? 0 : 180)}deg);
    transition: transform 0.5s ease;
    margin-right: 10px;
`;

const MenuHeadLine = styled(NavigationHeadline)`
    margin: 20px 0 14px 0;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        text-align: center;
        margin: 0 0 14px 0;
    }
`;

const ContentMenu = ({
    title,
    subTitle,
    mainNavItems,
    subNavItems,
    backButtonTo,
    backButtonLabel,
}) => {
    const isSmall = useIsMobile();
    const { t } = useTranslate();
    const location = useLocation();
    const currentRoute = location.pathname;
    const showBackButton = isSmall && backButtonTo && backButtonLabel;

    const getLabel = (mainNavItems, type = 'default') => {
        const currentItem = mainNavItems.find(item =>
            currentRoute.includes(item.path)
        );
        const label =
            type === 'short' ? currentItem?.labelShort : currentItem?.label;
        return t(label);
    };

    const accessableMainNavItems = mainNavItems.filter(
        item => item.hasAccessRights
    );

    return (
        <Menu>
            {showBackButton && (
                <BigBackButton to={backButtonTo} label={backButtonLabel} />
            )}
            <MenuHeadLine
                label={
                    accessableMainNavItems?.length > 1
                        ? title
                        : getLabel(accessableMainNavItems, 'short')
                }
            />
            {accessableMainNavItems?.length > 1 && (
                <MenuContainer>
                    <MainItems
                        mainNavItems={accessableMainNavItems}
                        currentMainItemLabel={getLabel(accessableMainNavItems)}
                    />
                </MenuContainer>
            )}
            {subNavItems?.length > 0 && (
                <SubMenuContainer>
                    <SubItems
                        subNavItems={subNavItems}
                        currentMainItemLabel={
                            accessableMainNavItems.length > 1
                                ? getLabel(accessableMainNavItems)
                                : undefined
                        }
                        subTitle={subTitle}
                    />
                </SubMenuContainer>
            )}
        </Menu>
    );
};

ContentMenu.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    mainNavItems: PropTypes.array,
    subNavItems: PropTypes.array,
    backButtonTo: PropTypes.string,
    backButtonLabel: PropTypes.string,
};

export default ContentMenu;
