import { arrayToObj } from '@mspecs/shared-utils';
import { CUSTOMER_PAGE_ACTION_TYPES as ACTION_TYPES } from '../actions/customer-page-actions.js';
import { FORM_OBJECT_KEYS } from '~/features/customerpage/components/customerpage-constants';

const initialState = {
    formInvitations: {},
    formInvitationContacts: [],
    customerPageSeller: [],
    tokens: {},
    forms: [],
    formData: {},
    allFormData: [],
    isLoading: false,
    selectedFormIndex: 0,
    selectedSubForm: null,
};

export default function customerPage(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.REMOVE_FORM_INVITATION_CONTACT: {
            return {
                ...state,
                formInvitationContacts: state.formInvitationContacts.filter(
                    contact => !action.payload.includes(contact.id)
                ),
            };
        }
        case ACTION_TYPES.TOGGLE_FORM_INVITATION_CONTACT: {
            return {
                ...state,
                formInvitationContacts: state.formInvitationContacts.concat(
                    action.payload
                ),
            };
        }
        case ACTION_TYPES.ADD_FORM_INVITATION: {
            return {
                ...state,
                formInvitationContacts: state.formInvitationContacts.concat(
                    action.payload.formInvitationContacts
                ),
                formInvitations: {
                    ...state.formInvitations,
                    [action.payload.formInvitations[0].formId]:
                        action.payload.formInvitations[0],
                },
                isLoading: false,
            };
        }
        case ACTION_TYPES.UPDATE_FORM_INVITATION: {
            return {
                ...state,
                formInvitations: {
                    ...state.formInvitations,
                    [action.payload.formInvitation.formId]:
                        action.payload.formInvitation,
                },
                isLoading: false,
            };
        }
        case ACTION_TYPES.GENERATE_TOKEN: {
            return {
                ...state,
                tokens: {
                    ...state.tokens,
                    ...action.payload,
                },
            };
        }
        case ACTION_TYPES.GET_FORM_INVITATIONS: {
            return {
                ...state,
                formInvitations: { ...action.payload },
            };
        }
        case ACTION_TYPES.GET_FORM_INVITATION: {
            return {
                ...state,
                formInvitations: arrayToObj(action.payload.formInvitations, {
                    key: 'formId',
                }),
                formInvitationContacts: [
                    ...action.payload.formInvitationContacts,
                ],
                forms: [...action.payload.forms],
            };
        }
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ACTION_TYPES.GET_FORM_DATA: {
            return {
                ...state,
                formData: action.payload.formData,
                isLoading: false,
            };
        }
        case ACTION_TYPES.SET_SELECTED_FORM_INDEX: {
            return {
                ...state,
                selectedFormIndex: action.payload,
            };
        }
        case ACTION_TYPES.GET_ALL_FORM_DATA: {
            return {
                ...state,
                allFormData: [...action.payload],
                isLoading: false,
            };
        }
        case ACTION_TYPES.SET_SELECTED_FORM: {
            const selectedIndex =
                action.payload.selectedFormIndex != null
                    ? action.payload.selectedFormIndex
                    : state.selectedFormIndex;

            let subForm = action.payload.selectedSubForm;

            if (!subForm) {
                const form = state.forms[selectedIndex];
                subForm = FORM_OBJECT_KEYS[form.body.key]?.[0];
            }

            return {
                ...state,
                selectedFormIndex: selectedIndex,
                selectedSubForm: subForm,
            };
        }
        case ACTION_TYPES.MERGE_FORM_DATA: {
            return {
                ...state,
                formData: {},
                formInvitations: {
                    ...state.formInvitations,
                    [action.payload]: {
                        ...state.formInvitations[action.payload],
                        formStatus: 'ENUM_FORM_INVITATIONS_FORM_STATUS_MERGED',
                    },
                },
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
}
