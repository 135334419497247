import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import Select, { createFilter } from 'react-select';
import { compose, branch } from 'recompose';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/react';
import isEqual from 'fast-deep-equal';
import pick from 'lodash-es/pick';
import omit from 'lodash-es/omit';
import {
    ClearIndicator,
    DropdownIndicator,
    getSelectStyles,
    Option,
    MenuList,
} from './styled-components';
import SelectInput from './styled-components/select-input-component';
import {
    withInputValidation,
    withFinalFormField,
    ErrorWrapper,
    withLabel,
    withValidationWrapper,
} from '../../../../utils/hoc';
import { defaultNamespace, useTranslate } from '@mspecs/shared-utils';
import { errorPropKeys } from '../../../../utils/hoc/with-error-wrapper-hoc';
import SelectMenu from './styled-components/select-menu-component';
import useIsInsideSidebar from './use-is-inside-sidebar-hook';

const SelectContainer = styled.div`
    width: 100%;
    font-size: 16px;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        font-size: 14px;
    }
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 22px;
    margin-right: 6px;
`;

const handleSortingOptions = (x, y) => {
    if (x.value === null && y.value !== null) return -1;
    if (x.label > y.label) return 1;
    else if (y.label > x.label) return -1;
    else return 0;
};

export const MspxSelect = props => {
    const {
        options: _options,
        value: _value,
        onBlur: _onBlur,
        getOptionValue,
        defaultValue: _defaultValue,
        disabled,
        onChange: _onChange,
        invalid,
        search,
        isExplicitSearchBar,
        sort,
        styles,
        placeholder,
        isMulti,
        components,
        matchFrom = 'start',
        optionTranslationOptions = { ns: defaultNamespace },
        saved,
        ...restProps
    } = props;

    const { ref, isInsideSidebar } = useIsInsideSidebar(el => el.inputRef);

    const errorProps = pick(props, errorPropKeys);
    const componentProps = omit(restProps, errorPropKeys);

    const { t } = useTranslate();

    const filterOptions = useMemo(
        () =>
            createFilter({
                ignoreCase: true,
                ignoreAccents: true,
                trim: true,
                matchFrom: matchFrom,
            }),
        [matchFrom]
    );

    const placeholderText = useMemo(() => {
        if (typeof placeholder === 'string') return placeholder;
        return placeholder ?? search ? t('CHOOSE_OR_SEARCH') : t('CHOOSE');
    }, [placeholder, search, t]);

    const translateOption = option => ({
        ...option,
        label: t(option.label, optionTranslationOptions),
        ...(option.options
            ? { options: option.options.map(translateOption) }
            : {}),
    });

    const options = useMemo(() => {
        if (_options) {
            const optionsList = _options.map(option =>
                typeof option === 'string'
                    ? { value: option, label: t(option) }
                    : translateOption(option)
            );
            const orderedList = sort
                ? optionsList.sort(handleSortingOptions)
                : optionsList;
            return orderedList;
        } else {
            return [];
        }
    }, [_options, t]);

    const getValue = value => {
        if (value == null) return value;

        const flattenedOptions = options
            .map(o =>
                o.options
                    ? o.options.sort(sort ? handleSortingOptions : x => x)
                    : o
            )
            .flat();

        // Find the full object for each selected value
        // Multiselects's value is of type string when component initialises.
        // ..therefore we need the isArray check.
        if (isMulti) {
            return Array.isArray(value)
                ? value.map(x =>
                      flattenedOptions.find(
                          option => getOptionValue(option) === x
                      )
                  )
                : [];
        } else {
            return (
                flattenedOptions.find(option =>
                    isEqual(value, getOptionValue(option))
                ) || ''
            );
        }
    };

    const value = getValue(_value);
    const defaultValue = getValue(_defaultValue);

    const onChange = useCallback(
        value => {
            if (isMulti) {
                _onChange(value.length ? value : null);
            } else {
                if (value && getOptionValue(value) !== '') {
                    _onChange(getOptionValue(value));
                } else {
                    _onChange(value);
                }
            }
        },
        [isMulti, _onChange]
    );

    const onBlur = () => {
        isMulti
            ? _onBlur(value && value.length ? value : null)
            : _onBlur(value && getOptionValue(value));
    };

    return (
        <SelectContainer>
            <ClassNames>
                {({ css }) => (
                    <ErrorWrapper
                        {...errorProps}
                        errorIconClassName={css`
                            margin-right: 25px;
                        `}
                    >
                        <Select
                            ref={ref}
                            id="react-select-container"
                            components={{
                                DropdownIndicator,
                                ClearIndicator,
                                MenuList,
                                Option,
                                Input: SelectInput,
                                Menu: SelectMenu,
                                ...components,
                            }}
                            defaultValue={defaultValue} //use only in Final Form
                            invalid={invalid}
                            isClearable
                            isDisabled={disabled}
                            isSaved={saved}
                            isMulti={isMulti}
                            isSearchable={search}
                            isExplicitSearchBar={isExplicitSearchBar}
                            blurInputOnSelect={false}
                            sort={sort}
                            filterOption={filterOptions}
                            formatOptionLabel={x => (
                                <Flex>
                                    {x.img && <Image src={x.img} />}
                                    <span>{x.label}</span>
                                </Flex>
                            )}
                            menuPlacement="auto"
                            noOptionsMessage={() => t('NO_RESULT_MATCH')}
                            onBlur={onBlur}
                            onChange={onChange}
                            options={options}
                            placeholder={placeholderText}
                            menuPortalTarget={
                                isInsideSidebar ? null : document.body
                            }
                            styles={{
                                ...{
                                    menuPortal: base => ({
                                        ...base,
                                        zIndex: 9999,
                                    }),
                                },
                                ...getSelectStyles(styles),
                            }}
                            value={value}
                            {...componentProps}
                        />
                    </ErrorWrapper>
                )}
            </ClassNames>
        </SelectContainer>
    );
};

MspxSelect.propTypes = {
    components: PropTypes.object,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    getOptionValue: PropTypes.func,
    isOptionDisabled: PropTypes.func,
    hideRequiredHint: PropTypes.bool,
    invalid: PropTypes.bool,
    isMulti: PropTypes.bool,
    isSelected: PropTypes.bool,
    sort: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    matchFrom: PropTypes.oneOf(['start', 'any']),
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.object,
            PropTypes.shape({
                value: PropTypes.any.isRequired,
                label: PropTypes.any.isRequired,
            }),
        ])
    ),
    required: PropTypes.bool,
    search: PropTypes.bool,
    isExplicitSearchBar: PropTypes.bool,
    styles: PropTypes.object,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    optionTranslationOptions: PropTypes.object,
};

MspxSelect.defaultProps = {
    defaultValue: null,
    disabled: false,
    getOptionValue: option => option.value,
    isMulti: false,
    isSelected: false,
    search: false,
    isExplicitSearchBar: false,
    sort: true,
    onBlur: x => x,
    styles: {},
};

export default compose(
    withValidationWrapper,
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    ),
    withLabel
)(MspxSelect);
