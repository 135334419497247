import { ac } from '../../../utils/redux-utils';
import {
    notificationUpdateError,
    notificationUpdateSuccess,
} from '~/utils/notification-utils';
import { legacyApi } from '@mspecs/shared-utils';
import { MARKETING_PLACES } from '~/features/external-marketing-places/marketing-constants';

export const ACTIONS = {
    EXTERNAL_MARKETING_PLACE_DATA_GET_DEAL_DATA:
        'EXTERNAL_MARKETING_PLACE_DATA_GET_DEAL_DATA',
    EXTERNAL_MARKETING_PLACE_DATA_GET_DEAL_DATA_SUCCESS:
        'EXTERNAL_MARKETING_PLACE_DATA_GET_DEAL_DATA_SUCCESS',
    EXTERNAL_MARKETING_PLACE_DATA_GET_DEAL_DATA_ERROR:
        'EXTERNAL_MARKETING_PLACE_DATA_GET_DEAL_DATA_ERROR',

    EXTERNAL_MARKETING_PLACE_DATA_UPDATE:
        'EXTERNAL_MARKETING_PLACE_DATA_UPDATE',
    EXTERNAL_MARKETING_PLACE_DATA_UPDATE_SUCCESS:
        'EXTERNAL_MARKETING_PLACE_DATA_UPDATE_SUCCESS',
    EXTERNAL_MARKETING_PLACE_DATA_UPDATE_ERROR:
        'EXTERNAL_MARKETING_PLACE_DATA_UPDATE_ERROR',

    EXTERNAL_MARKETING_PLACE_DATA_PUBLISH:
        'EXTERNAL_MARKETING_PLACE_DATA_PUBLISH',
    EXTERNAL_MARKETING_PLACE_DATA_PUBLISH_SUCCESS:
        'EXTERNAL_MARKETING_PLACE_DATA_PUBLISH_SUCCESS',
    EXTERNAL_MARKETING_PLACE_DATA_PUBLISH_ERROR:
        'EXTERNAL_MARKETING_PLACE_DATA_PUBLISH_ERROR',

    EXTERNAL_MARKETING_PLACE_DATA_UN_PUBLISH:
        'EXTERNAL_MARKETING_PLACE_DATA_UN_PUBLISH',
    EXTERNAL_MARKETING_PLACE_DATA_UN_PUBLISH_SUCCESS:
        'EXTERNAL_MARKETING_PLACE_DATA_UN_PUBLISH_SUCCESS',
    EXTERNAL_MARKETING_PLACE_DATA_UN_PUBLISH_ERROR:
        'EXTERNAL_MARKETING_PLACE_DATA_UN_PUBLISH_ERROR',

    EXTERNAL_MARKETING_PLACE_HEMNET_MARK_AS_SOLD:
        'EXTERNAL_MARKETING_PLACE_HEMNET_MARK_AS_SOLD',
};

const getDealDataAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_GET_DEAL_DATA);
const getDealDataSuccessAction = ({
    marketingPlace,
    dealId,
    marketingPlaceData,
}) =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_GET_DEAL_DATA_SUCCESS, {
        marketingPlace,
        dealId,
        marketingPlaceData,
    });
const getDealDataErrorAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_GET_DEAL_DATA_ERROR);

const updateDealDataAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_UPDATE);
const updateDealDataErrorAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_UPDATE_ERROR);
const updateDealDataSuccessAction = ({
    marketingPlace,
    dealId,
    marketingPlaceData,
}) =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_UPDATE_SUCCESS, {
        marketingPlace,
        dealId,
        marketingPlaceData,
    });

const publishDealDataAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_PUBLISH);
const publishDealDataSuccessAction = ({
    marketingPlace,
    dealId,
    marketingPlaceData,
}) =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_PUBLISH_SUCCESS, {
        marketingPlace,
        dealId,
        marketingPlaceData,
    });
const publishDealDataErrorAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_PUBLISH_ERROR);

const unpublishDealDataAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_UN_PUBLISH);
const unpublishDealDataSuccessAction = ({
    marketingPlace,
    dealId,
    marketingPlaceData,
}) =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_UN_PUBLISH_SUCCESS, {
        marketingPlace,
        dealId,
        marketingPlaceData,
    });
const unpublishDealDataErrorAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_DATA_UN_PUBLISH_ERROR);

const hemnetMarkAsSoldAction = () =>
    ac(ACTIONS.EXTERNAL_MARKETING_PLACE_HEMNET_MARK_AS_SOLD);

export function getDealData(marketingPlace, dealId) {
    return async dispatch => {
        try {
            dispatch(getDealDataAction());
            const marketingPlaceData = await legacyApi(
                `/v2/publishing/publish/${marketingPlace}/${dealId}`
            ).get();
            dispatch(
                getDealDataSuccessAction({
                    marketingPlace,
                    dealId,
                    marketingPlaceData,
                })
            );
            return marketingPlaceData;
        } catch (e) {
            dispatch(getDealDataErrorAction(e));
            throw e;
        }
    };
}

export function updateDealData(marketingPlace, dealId, data) {
    return async dispatch => {
        try {
            dispatch(updateDealDataAction());
            const marketingPlaceData = await legacyApi(
                `/v2/publishing/publish/${marketingPlace}/${dealId}`
            ).put(data);
            dispatch(
                updateDealDataSuccessAction({
                    marketingPlace,
                    dealId,
                    marketingPlaceData,
                })
            );
            notificationUpdateSuccess(dispatch);
            return marketingPlaceData;
        } catch (e) {
            dispatch(updateDealDataErrorAction());
            notificationUpdateError(dispatch);
            throw e;
        }
    };
}

export function publishDealData(marketingPlace, dealId) {
    return async dispatch => {
        try {
            dispatch(publishDealDataAction());
            const marketingPlaceData = await legacyApi(
                `/v2/publishing/publish/${marketingPlace}/${dealId}`
            ).post();
            dispatch(
                publishDealDataSuccessAction({
                    marketingPlace,
                    dealId,
                    marketingPlaceData,
                })
            );
            notificationUpdateSuccess(dispatch);
            return marketingPlaceData;
        } catch (e) {
            notificationUpdateError(dispatch);
            dispatch(publishDealDataErrorAction());
            throw e;
        }
    };
}

export function unpublishDealData(marketingPlace, dealId) {
    return async dispatch => {
        try {
            dispatch(unpublishDealDataAction());
            const marketingPlaceData = await legacyApi(
                `/v2/publishing/publish/${marketingPlace}/${dealId}`
            ).delete();
            dispatch(
                unpublishDealDataSuccessAction({
                    marketingPlace,
                    dealId,
                    marketingPlaceData,
                })
            );
            notificationUpdateSuccess(dispatch);
            return marketingPlaceData;
        } catch (e) {
            notificationUpdateError(dispatch);
            dispatch(unpublishDealDataErrorAction());
            throw e;
        }
    };
}

/**
 * Another way to unpublish a listing
 * @param dealId
 * @param organizationUnitId
 * @returns {Function}
 */
export function hemnetMarkAsSold(marketingPlace, dealId, data = {}) {
    return async dispatch => {
        try {
            dispatch(hemnetMarkAsSoldAction());
            const marketingPlaceData = await legacyApi(
                `/v2/publishing/hemnet/markAsSold/${marketingPlace}/${dealId}`
            ).put(data);
            dispatch(
                unpublishDealDataSuccessAction({
                    marketingPlace: MARKETING_PLACES.HEMNET,
                    dealId,
                    marketingPlaceData,
                })
            );
            notificationUpdateSuccess(dispatch);
            return marketingPlaceData;
        } catch (e) {
            notificationUpdateError(dispatch);
            dispatch(unpublishDealDataErrorAction());
            throw e;
        }
    };
}
