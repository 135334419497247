import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { useTranslate } from '@mspecs/shared-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BUTTON_TYPES, Button } from '../../button';

const BackButtonContainer = styled.div`
    padding: 6px 0;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        display: none;
    }
`;

const BackButton = styled(Button)`
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 18.5px;
    font-style: normal;
    font-weight: 400;
    justify-content: left;
    span {
        line-height: 0;
        margin-left: 5px;
    }
`;
const BackButtonText = styled.span`
    font-size: 18.5px;
`;

export default function ModalBackButton({ onClick, buttonText, ...props }) {
    const { t } = useTranslate();
    return (
        <BackButtonContainer>
            <BackButton
                onClick={onClick}
                {...props}
                buttonType={BUTTON_TYPES.TEXT}
            >
                <FontAwesomeIcon icon={faChevronLeft} />
                <BackButtonText>{t(buttonText)}</BackButtonText>
            </BackButton>
        </BackButtonContainer>
    );
}

ModalBackButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
};
ModalBackButton.defaultProps = {
    buttonText: 'BACK',
};
