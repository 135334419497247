import SessionService from '../../utils/services/session-service';
import { arrayToObj } from '@mspecs/shared-utils';
import { ac } from '../../utils/redux-utils';
import { legacyApi } from '@mspecs/shared-utils';

export const SESSION_USER_LOGIN = 'SESSION_USER_LOGIN';
export const SESSION_USER_LOGOUT = 'SESSION_USER_LOGOUT';
export const SESSION_USER_FAIL = 'SESSION_USER_FAIL';
export const SESSION_SET_USER_COOKIE_CONSENT =
    'SESSION_SET_USER_COOKIE_CONSENT';
export const SESSION_TOO_MANY_LOGIN_ATTEMPTS =
    'SESSION_TOO_MANY_LOGIN_ATTEMPTS';
export const RESET_USER_LOGIN_ERROR = 'RESET_USER_LOGIN_ERROR';

export const FETCH_SESSION_REQUEST = 'FETCH_SESSION_REQUEST';
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
export const FETCH_SESSION_ERROR = 'FETCH_SESSION_ERROR';

export const FETCH_SESSION_ADMIN_ORG_UNIT_SUCCESS =
    'FETCH_SESSION_ADMIN_ORG_UNIT_SUCCESS';
export const FETCH_SESSION_ORG_UNIT_SUCCESS = 'FETCH_SESSION_ORG_UNIT_SUCCESS';

function requestFetchSession() {
    return ac(FETCH_SESSION_REQUEST);
}

function sessionTooManyLoginAttempts() {
    return ac(SESSION_TOO_MANY_LOGIN_ATTEMPTS);
}

function sessionFailed() {
    return ac(SESSION_USER_FAIL);
}

export function resetLoginError() {
    return ac(RESET_USER_LOGIN_ERROR);
}

function successFetchSession(session) {
    return ac(FETCH_SESSION_SUCCESS, session);
}

function errorFetchSession(error) {
    return ac(FETCH_SESSION_ERROR, error);
}

function successFetchSessionAdminOrgUnits(orgAdminUnits) {
    return ac(FETCH_SESSION_ADMIN_ORG_UNIT_SUCCESS, orgAdminUnits);
}

function successFetchOrgUnits(orgUnits) {
    return ac(FETCH_SESSION_ORG_UNIT_SUCCESS, orgUnits);
}

export function setUserCookieConsent(cookieConsentDate, analyticsConsentDate) {
    return ac(SESSION_SET_USER_COOKIE_CONSENT, {
        cookieConsentDate,
        analyticsConsentDate,
    });
}

export function login(data) {
    return async dispatch => {
        try {
            await legacyApi('/login').post(data);
            dispatch(fetchSession());
        } catch (e) {
            if (e.type === 'TooManyRequestsError') {
                dispatch(sessionTooManyLoginAttempts());
            } else {
                dispatch(sessionFailed());
            }
        }
    };
}

export function loginAsUser(username) {
    return async dispatch => {
        try {
            await legacyApi(`/admin/supportLoginAs/${username}`).get();
            dispatch(fetchSession(true));
            window.location.href = '/';
        } catch (e) {
            if (e.type === 'TooManyRequestsError') {
                dispatch(sessionTooManyLoginAttempts());
            } else {
                dispatch(sessionFailed());
            }
        }
    };
}

export function logout() {
    return async dispatch => {
        try {
            const logoutUrl = legacyApi(
                `/logout?landingPage=${encodeURIComponent('/app/login')}`
            ).url;
            window.location = logoutUrl;
        } catch (e) {}
        dispatch({ type: SESSION_USER_LOGOUT });
    };
}

export const fetchSystemSettings = async () =>
    await legacyApi('/v2/session/systemSettings').get();

export function fetchSession(forceGet) {
    return async (dispatch, getState) => {
        const state = getState();
        if (!forceGet && state.session.id) {
            return Promise.resolve();
        }
        dispatch(requestFetchSession());
        try {
            const sessionResponse = await legacyApi('/v2/session').get();

            if (!sessionResponse.userAccount) {
                throw new Error('No logged in user found');
            }

            const { userAccount, ...rest } = sessionResponse;
            const { analyticsConsentDate, cookieConsentDate } = userAccount;

            const session = {
                id: userAccount.id,
                username: userAccount.username,
                isLoggedIn: true,
                cookie: {
                    analyticsConsentDate,
                    cookieConsentDate,
                },
                ...rest,
            };

            try {
                session.systemSettings = await fetchSystemSettings();
            } catch {}

            dispatch(successFetchSession(session));
        } catch (err) {
            dispatch(errorFetchSession(err));
        }
    };
}

export function fetchSessionAdminOrgUnits() {
    return dispatch =>
        SessionService.getAdminOrgUnits().then(orgAdminUnits => {
            dispatch(
                successFetchSessionAdminOrgUnits(arrayToObj(orgAdminUnits))
            );
        });
}

export function fetchOrganizationUnits(userAccountId) {
    return dispatch => {
        return legacyApi('/staff')
            .query({ q: `userAccountId = '${userAccountId}'` })
            .get()
            .then(staff => {
                if (!staff.length) {
                    return [];
                }
                const orgUnitArr = staff.map(x => x.organizationUnitId);
                return legacyApi('/organizationUnits')
                    .query({ q: `id IN ('${orgUnitArr.join("','")}')` })
                    .get();
            })
            .then(orgUnits => {
                dispatch(successFetchOrgUnits(arrayToObj(orgUnits)));
            });
    };
}
