import React from 'react';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { InputField } from '.';
import { compose, branch } from 'recompose';
import {
    withFinalFormField,
    withInputValidation,
    withValidationWrapper,
} from '../../../utils/hoc';

/* eslint-disable no-unused-vars, react/no-unused-prop-types */
const STANDARD_DECIMAL_SYMBOL = '.';

class NumberInput extends React.Component {
    static createViewValue(value, { decimalSymbol }) {
        if (!value) {
            return value;
        }

        let val = `${value}`;

        // There should only be ONE decimal symbol. We do a native replace
        if (decimalSymbol !== STANDARD_DECIMAL_SYMBOL) {
            val = val.replace(STANDARD_DECIMAL_SYMBOL, decimalSymbol);
        }

        return val;
    }

    static createModelValue(value, { decimalSymbol }) {
        if (!value) {
            return value;
        }

        let val = value;

        // There should only be ONE decimal symbol. We do a native replace
        if (decimalSymbol !== STANDARD_DECIMAL_SYMBOL) {
            val = val.replace(decimalSymbol, STANDARD_DECIMAL_SYMBOL);
        }

        // Sanitize, replace everything except - digits and .
        val = val.replace(/[^-0-9.]/g, '');

        if (val === '-') {
            // Only entering a - will result in NaN
            return -0;
        }

        return Number(val);
    }

    constructor(props) {
        super(props);
        const {
            value,

            maskPrefix,
            maskSuffix,
            integerLimit,
            allowDecimal,
            decimalLimit,
            allowNegative,
            requireDecimal,
            includeThousandsSeparator,
            thousandsSeparatorSymbol,
            decimalSymbol,
        } = props;

        this.mask = createNumberMask({
            prefix: maskPrefix,
            suffix: maskSuffix,
            integerLimit,
            decimalLimit,
            allowDecimal,
            allowNegative,
            requireDecimal,
            includeThousandsSeparator,
            thousandsSeparatorSymbol,
            decimalSymbol,
        });

        this.state = {
            viewValue: NumberInput.createViewValue(value, { decimalSymbol }),
            modelValue: value,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { value, decimalSymbol } = props;

        if (value !== state.modelValue) {
            return {
                viewValue: NumberInput.createViewValue(value, {
                    decimalSymbol,
                }),
                modelValue: value,
            };
        }
        return null;
    }

    parseValue = value => {
        return NumberInput.createModelValue(value, {
            decimalSymbol: this.props.decimalSymbol,
        });
    };

    onBlur = event => {
        const { value } = event.target;
        const valueOut = !value ? this.props.value : Number(value);
        this.props.onBlur(valueOut);
    };

    onChange = event => {
        const newViewVal = event.target.value;
        const newModelVal = this.parseValue(newViewVal);

        if (this.state.modelValue !== newModelVal) {
            this.props.onChange(newModelVal, event);
        }

        this.setState({
            viewValue: newViewVal,
            modelValue: newModelVal,
        });
    };
    /* eslint-enable eqeqeq */

    render() {
        const {
            value = '',
            maskPrefix,
            maskSuffix,
            integerLimit,
            allowDecimal,
            decimalLimit,
            allowNegative,
            requireDecimal,
            includeThousandsSeparator,
            thousandsSeparatorSymbol,
            decimalSymbol,
            onChange,
            ...rest
        } = this.props;

        return (
            <InputField
                value={this.state.viewValue}
                onBlur={this.onBlur}
                onChange={this.onChange}
                {...rest}
            />
        );
    }
}

export { NumberInput as RawNumberInput };
export default compose(
    withValidationWrapper,
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    )
)(NumberInput);

NumberInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    name: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    maskPrefix: PropTypes.string,
    maskSuffix: PropTypes.string,
    allowDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    decimalLimit: PropTypes.number,
    integerLimit: PropTypes.number,
    requireDecimal: PropTypes.bool,
    includeThousandsSeparator: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    thousandsSeparatorSymbol: PropTypes.string,
};

NumberInput.defaultProps = {
    t: x => x,
    maskPrefix: '',
    maskSuffix: '',
    name: '',
    onBlur: () => {},
    onChange: () => {},
    allowDecimal: true,
    decimalLimit: null,
    integerLimit: null,
    allowNegative: false,
    requireDecimal: false,
    includeThousandsSeparator: true,
    decimalSymbol: ',',
    thousandsSeparatorSymbol: ' ',
};
