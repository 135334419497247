import { useTheme } from '@emotion/react';
import { useMemo } from 'react';
import { useWindowSize } from '../hooks/use-window-size-hook';

const breakpointToNumber = value => value.replace('px', '');

export function useCurrentBreakpoint() {
    const windowSize = useWindowSize(50);
    const { responsiveBreakpoints } = useTheme();

    const currentBreakpoint = useMemo(() => {
        const windowWidth = windowSize.width;

        if (windowWidth < breakpointToNumber(responsiveBreakpoints.s)) {
            return 'xs';
        }
        if (windowWidth < breakpointToNumber(responsiveBreakpoints.m)) {
            return 's';
        }
        if (windowWidth < breakpointToNumber(responsiveBreakpoints.l)) {
            return 'm';
        }
        if (windowWidth < breakpointToNumber(responsiveBreakpoints.xl)) {
            return 'l';
        }

        return 'xl';
    }, [windowSize.width, responsiveBreakpoints]);

    return currentBreakpoint;
}

export const useIsMobile = () => {
    const currentBreakpoint = useCurrentBreakpoint();
    return currentBreakpoint === 'xs';
};

export const isTouchDevice = () =>
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;
