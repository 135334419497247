import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useStack from './hooks/use-stack';
import { BackButton, ButtonToolbar } from '../../button';

const StyledButtonToolbar = styled(ButtonToolbar)`
    background-color: rgba(63, 76, 93, 0.9);
    border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
    justify-content: space-between;
    padding: 15px 30px;
`;

const StyledButton = styled(BackButton)`
    color: #fff;
`;

const StackButtonBar = ({ children }) => {
    const { stack, navigateStack } = useStack();

    if (stack.length <= 1) {
        return null;
    }

    return (
        <StyledButtonToolbar left>
            <StyledButton label="BACK" onClick={() => navigateStack(-1)} />
            {children}
        </StyledButtonToolbar>
    );
};

StackButtonBar.propTypes = {
    children: PropTypes.node,
};

export default StackButtonBar;
