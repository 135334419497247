import React from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { Modal } from '.';

const _contentClass = theme => css`
    flex-direction: column;
    padding: 20px;
`;

const popupClass = (theme, { topOffset }) => css`
    margin-top: ${topOffset + 10}px; // compenaste for top navigation
    border-radius: ${theme.borderRadius.medium};
    max-height: calc(100vh - 100px);
    max-width: 95%;
    box-shadow: ${theme.boxShadows.default};
`;
const _modalClass = (theme, { isMobileFullscreen, topOffset }) => css`
    align-self: flex-start;
    overflow: hidden;
    height: auto;
    background-color: ${theme.colors.bgPrimary};
    ${isMobileFullscreen
        ? `margin-top: ${topOffset}px;border-radius: 0;max-height: calc(100% - ${topOffset}px);max-width: 100%;height: 100%;`
        : popupClass(theme, { topOffset })}
    @media (min-width: ${theme.responsiveBreakpoints.s}) {
        ${popupClass(theme, { topOffset })}
    }
`;

const leftPositionClass = (theme, { isMobileFullscreen }) => css`
    justify-content: flex-start;
    padding-left: ${isMobileFullscreen ? 0 : theme.spacing.default};
    @media (min-width: ${theme.responsiveBreakpoints.s}) {
        padding-left: ${theme.spacing.default};
    }
`;
const rightPositionClass = (theme, { isMobileFullscreen }) => css`
    justify-content: flex-end;
    padding-right: ${isMobileFullscreen ? 0 : theme.spacing.default};
    @media (min-width: ${theme.responsiveBreakpoints.s}) {
        padding-right: ${theme.spacing.default};
    }
`;
const overLayClass = css`
    background-color: transparent;
`;

const combineCss = (theme, styleProps) => (defaultCss, customCss) =>
    css(
        defaultCss(theme, styleProps),
        typeof customCss === 'function' ? customCss?.(theme) : customCss
    );

const PopupModal = ({
    children,
    position,
    modalClass,
    contentClass,
    isMobileFullscreen,
    topOffset = 0,
    ...props
}) => {
    const styleProps = { isMobileFullscreen, topOffset };
    const theme = useTheme();
    const cssCombiner = combineCss(theme, styleProps);

    return (
        <Modal
            modalClass={cssCombiner(_modalClass, modalClass)}
            contentClass={cssCombiner(_contentClass, contentClass)}
            renderCloseButton={() => null}
            renderBackButton={() => null}
            overlayClassNames={({ theme }) =>
                [
                    overLayClass,
                    position === 'left' && leftPositionClass(theme, styleProps),
                    position === 'right' &&
                        rightPositionClass(theme, styleProps),
                ].filter(Boolean)
            }
            {...props}
        >
            {children}
        </Modal>
    );
};

PopupModal.propTypes = {
    position: PropTypes.oneOf(['left', 'right', 'center']),
    isMobileFullscreen: PropTypes.bool,
    topOffset: PropTypes.number,
};

PopupModal.defaultProps = {
    position: 'center',
};
export default PopupModal;
