import styled from '@emotion/styled';
import { TOAST_TYPES } from '../constants';
import PropTypes from 'prop-types';
import {
    ErrorToast,
    SuccessToast,
    InfoToast,
    LoadingToast,
    WarningToast,
} from '../toast-wrapper-component';

const ToastContainer = styled('div')`
    position: fixed;
    z-index: 1000;
    bottom: 2rem;
    left: 2rem;
    width: 300px;

    @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
        width: 438px;
    }
`;

export const TOAST_COMPONENTS = {
    [TOAST_TYPES.ERROR]: ErrorToast,
    [TOAST_TYPES.INFO]: InfoToast,
    [TOAST_TYPES.LOADING]: LoadingToast,
    [TOAST_TYPES.WARNING]: WarningToast,
    [TOAST_TYPES.SUCCESS]: SuccessToast,
};

const Toast = ({ messages, onClose }) => {
    return messages.length ? (
        <ToastContainer>
            {messages.map(message => {
                const { title, body, id, type } = message;
                const toastProps = {
                    title,
                    body,
                    type,
                    close: () => onClose(message),
                };
                const ToastComponent = TOAST_COMPONENTS[type] ?? SuccessToast;

                return <ToastComponent key={id} {...toastProps} />;
            })}
        </ToastContainer>
    ) : null;
};

export default Toast;

Toast.propTypes = {
    messages: PropTypes.array,
    onClose: PropTypes.func,
};
