import { v1 as uuidv1 } from 'uuid';
import { notificationVar } from '../stores';
import { NOTIFICATION_TYPES } from '@mspecs/ui-components/src/components/notifications/constants';

function createNotificationObject(message, type) {
    return {
        id: uuidv1(),
        message,
        type,
    };
}

const addNotification = newNotification => {
    const currentNotifications = notificationVar();
    notificationVar({
        ...currentNotifications,
        messages: {
            ...currentNotifications.messages,
            [newNotification.id]: newNotification,
        },
    });
};

export const removeNotification = deletedNotification => {
    const currentNotifications = notificationVar();
    const { [deletedNotification.id]: deleted, ...messagesWithoutDeleted } =
        currentNotifications.messages;
    notificationVar({
        ...currentNotifications,
        messages: {
            ...messagesWithoutDeleted,
        },
    });
};

const displayNotification = (notification, timer = 4000) => {
    addNotification(notification);
    setTimeout(() => {
        removeNotification(notification);
    }, timer);
};

export const displaySuccess = (message, timer) => {
    const notification = createNotificationObject(
        message,
        NOTIFICATION_TYPES.SUCCESS
    );
    displayNotification(notification, timer);
};

export const displayError = (message, timer) => {
    const notification = createNotificationObject(
        message,
        NOTIFICATION_TYPES.ERROR
    );
    displayNotification(notification, timer);
};
