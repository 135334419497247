import { useReactiveVar } from '@apollo/client';
import { toastsVar } from '../stores';
import { removeToast } from '../services';
import { notificationVar } from '~/features/notifications/stores';
import { removeNotification } from '~/features/notifications/services';
import { Toast } from '@mspecs/ui-components';

const ToastContainer = () => {
    const toasts = useReactiveVar(toastsVar);
    const notifications = useReactiveVar(notificationVar);
    const toastMessages = Object.values(toasts);
    const legacyNotifications = Object.values(notifications.messages);

    const messages = [
        ...toastMessages,
        ...legacyNotifications.map(notification => ({
            title: notification.message,
            body: '',
            type: notification.type,
            id: notification.id,
            isLegacy: true,
        })),
    ];

    const onClose = message => {
        if (message.isLegacy) {
            removeNotification(message);
        }
        removeToast(message);
    };
    return <Toast messages={messages} onClose={onClose} />;
};

export default ToastContainer;
