import { displayError } from '~/features/notifications/services';
import { uploadFilesToDeal } from './file-actions';
import { ac } from '../../utils/redux-utils';
import { legacyApi } from '@mspecs/shared-utils';
import { notificationUpdateSuccess } from '~/utils/notification-utils';

export const TAMBUR_ACTION_TYPES = {
    TOGGLE_ATTACHMENT_EXCLUDE: 'TOGGLE_ATTACHMENT_EXCLUDE',
    SUCCESS_CREATE_TAMBUR_CASE: 'SUCCESS_CREATE_TAMBUR_CASE',
    SUCCESS_UPLOAD_TAMBUR_FILES: 'SUCCESS_UPLOAD_TAMBUR_FILES',
    SUCCESS_DELETE_TAMBUR_CASE: 'SUCCESS_DELETE_TAMBUR_CASE',
    ERROR: 'ERROR',
    RESET_ERROR: 'RESET_ERROR',
    INIT_TAMBUR_CASE: 'INIT_TAMBUR_CASE',
    SUCCESS_SET_VALIDATION: 'SUECCSS_SET_VALIDATION',
    SUCCESS_SET_ASSISTANTS: 'SUCCESS_SET_ASSISTANTS',
    SET_INIT_LOADING_INDICATOR: 'SET_INIT_LOADING_INDICATOR',
    SET_PROTECTED_IDENTITY: 'SET_PROTECTED_IDENTITY',
    RESET_SENT_FILES: 'RESET_SENT_FILES',
};

const successCreateTamburCase = data =>
    ac(TAMBUR_ACTION_TYPES.SUCCESS_CREATE_TAMBUR_CASE, data);

const successUploadFilesToTambur = data =>
    ac(TAMBUR_ACTION_TYPES.SUCCESS_UPLOAD_TAMBUR_FILES, data);

export const errorCreateTamburCase = data =>
    ac(TAMBUR_ACTION_TYPES.ERROR, data);

export const resetTamburCaseErrors = () =>
    ac(TAMBUR_ACTION_TYPES.RESET_ERROR, {});

const startLoading = () =>
    ac(TAMBUR_ACTION_TYPES.SET_INIT_LOADING_INDICATOR, true);

export const setProtectedIdentity = data =>
    ac(TAMBUR_ACTION_TYPES.SET_PROTECTED_IDENTITY, data);

export const successInitTamburCase = data =>
    ac(TAMBUR_ACTION_TYPES.INIT_TAMBUR_CASE, data);

export const successDeleteTamburCase = data =>
    ac(TAMBUR_ACTION_TYPES.SUCCESS_DELETE_TAMBUR_CASE, data);

const successValidateTamburData = data =>
    ac(TAMBUR_ACTION_TYPES.SUCCESS_SET_VALIDATION, data);

export const resetSentFiles = () => ac(TAMBUR_ACTION_TYPES.RESET_SENT_FILES);

export const setAssistantForCase = data =>
    ac(TAMBUR_ACTION_TYPES.SUCCESS_SET_ASSISTANTS, data);

export function uploadAttachments(files, dealId) {
    return async dispatch => {
        try {
            const uploadedFiles = await Promise.all(
                files.map(addedFile =>
                    dispatch(uploadFilesToDeal([addedFile], dealId))
                )
            );
            notificationUpdateSuccess();
            return uploadedFiles;
        } catch (e) {
            displayError('UPLOAD_ATTACHMENTS_ERROR_MSG');
        }
    };
}
export function createTamburCase(dealId, listOfFiles) {
    return async (dispatch, getState) => {
        const { assistantContactIds } = getState().tambur;
        try {
            dispatch(startLoading());
            const data = await legacyApi(
                `/v3/financialSettlement/${dealId}`
            ).post({ assistingBrokerContactIds: assistantContactIds });
            if (listOfFiles.length > 0) {
                await legacyApi(
                    `/v3/financialSettlement/${dealId}/documents`
                ).post(listOfFiles);
            }
            dispatch(successCreateTamburCase(data));
            notificationUpdateSuccess();
        } catch (e) {
            displayError(e.message);
            dispatch(errorCreateTamburCase(e.errors || []));
        }
    };
}

export function deleteTamburCase(dealId) {
    return async dispatch => {
        try {
            const data = await legacyApi(
                `/v3/financialSettlement/${dealId}?forceCleanUp=true`
            ).delete();
            return data;
        } catch (e) {
            displayError(e.message);
            throw e;
        }
    };
}

export function uploadFilestoTambur(dealId, listOfFiles) {
    return async dispatch => {
        try {
            dispatch(startLoading());
            await legacyApi(`/v3/financialSettlement/${dealId}/documents`).post(
                listOfFiles
            );
            const data = await legacyApi(
                `/v3/financialSettlement/${dealId}/exportDetails`
            ).get();
            dispatch(successUploadFilesToTambur(data));
            notificationUpdateSuccess();
        } catch (e) {
            dispatch(errorCreateTamburCase(e.errors || []));
        }
    };
}

export function getValidationWarnings(dealId) {
    return async dispatch => {
        try {
            const data = await legacyApi(
                `/v3/financialSettlement/${dealId}/preview`
            ).get();
            if (data.error && data.error.errors) {
                dispatch(successValidateTamburData(data.error.errors));
            } else {
                dispatch(successValidateTamburData([]));
            }
        } catch (e) {
            dispatch(successValidateTamburData([]));
        }
    };
}
