import { ac } from '../../utils/redux-utils';
import { notificationUpdateError } from '~/utils/notification-utils';
import { legacyApi, arrayToObj } from '@mspecs/shared-utils';

export const ACTION_TYPES = {
    GET_COUNTRIES: 'GET_COUNTRIES',
    GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
    GET_COUNTRIES_ERROR: 'GET_COUNTRIES_ERROR',
};

const getCountries = () => ac(ACTION_TYPES.GET_COUNTRIES);

const getCountriesSuccess = countries =>
    ac(ACTION_TYPES.GET_COUNTRIES_SUCCESS, countries);

const getCountriesError = () => ac(ACTION_TYPES.GET_COUNTRIES_SUCCESS);

export function getAllCountries() {
    return async (dispatch, getState) => {
        const { countries } = getState();
        if (countries && Object.values(countries).length) {
            return;
        }

        try {
            dispatch(getCountries());
            const fetchedCountries = await legacyApi('/countries').get();
            dispatch(getCountriesSuccess(arrayToObj(fetchedCountries)));
        } catch (e) {
            dispatch(getCountriesError());
            notificationUpdateError(dispatch);
            throw e;
        }
    };
}
