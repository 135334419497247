import { React, memo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

export const StyledMenuItem = styled(NavLink)`
    padding: 10px;
    height: 44px;
    transition: background-color 200ms ease-in-out;
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizeNormal};
    margin-bottom: 5px;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-weight: 500;
    &:focus {
        box-shadow: none;
        background: white;
    }
    &.active {
        background: ${({ theme }) => theme.colors.primaryColor15};
        color: ${({ theme }) => theme.colors.primaryColor};

        &:hover {
            background: ${({ theme }) => theme.colors.primaryColor10};
        }
    }
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        background: ${({ size, theme }) =>
            size === 'small' ? theme.colors.primaryColor10 : 'none'};
        height: 34px;
        padding: ${({ size }) => (size === 'small' ? '5px' : '10px')} 10px;
        justify-content: ${({ size }) =>
            size === 'small' ? 'center' : 'left'};
        border-radius: ${({ theme }) => theme.borderRadius.medium};
        &:hover {
            text-decoration: none;
            background: ${({ theme }) => theme.colors.primaryColor5};
            cursor: pointer;
        }
    }
`;

const MenuItem = memo(({ to, size, children }) => {
    return (
        <StyledMenuItem to={to} size={size}>
            {children}
        </StyledMenuItem>
    );
});

export default MenuItem;

MenuItem.propTypes = {
    to: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.node,
};
