import {
    DndContext,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import PropTypes from 'prop-types';
import React from 'react';
import { arrayMove } from '@dnd-kit/sortable';

const DragAndDropContext = ({
    children,
    handleDragOver,
    handleDragEnd,
    onDragCancel,
    ...props
}) => {
    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        })
    );

    const onDragOver = ({ over, active }) => {
        if (!over?.id) {
            return;
        }

        const sourceContainer = active.data.current.sortable?.containerId;
        const destinationContainer =
            over.data.current?.sortable?.containerId || over.id;

        if (sourceContainer !== destinationContainer) {
            const sourceIndex = active.data.current.sortable.index;
            const destinationIndex = over.data.current?.sortable.index || 0;

            return handleDragOver?.({
                sourceIndex,
                destinationIndex,
                sourceContainer,
                destinationContainer,
                sourceId: over.id,
                destinationId: active.id,
            });
        }
    };

    const onDragEnd = ({ active, over }) => {
        if (!over?.data?.current) {
            return onDragCancel?.();
        }

        const destinationContainer =
            over.data.current?.sortable.containerId || over.id;

        const sourceIndex = active.data.current.sortable.index;
        const destinationIndex = over.data.current?.sortable.index || 0;
        const destinationItems = over.data.current?.sortable.items ?? [];

        return handleDragEnd?.({
            destinationContainer,
            sourceIndex,
            sourceId: active.id,
            destinationIndex,
            destinationId: over.id,
            newOrder: arrayMove(
                destinationItems,
                sourceIndex,
                destinationIndex
            ),
        });
    };

    return (
        <DndContext
            sensors={sensors}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            onDragCancel={onDragCancel}
            {...props}
        >
            {children}
        </DndContext>
    );
};

DragAndDropContext.propTypes = {
    children: PropTypes.any,
    handleDragOver: PropTypes.func,
    handleDragEnd: PropTypes.func,
    onDragCancel: PropTypes.func,
};

export default DragAndDropContext;
