import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const defaultCellRenderer = cell => cell.render('Cell');

const TableBodyRow = ({
    row,
    index,
    children,
    style = {},
    prepareRow,
    isItemLoaded,
    getRowProps,
    outerRef,
    containerProps = {},
    cellRender = defaultCellRenderer,
}) => {
    if (isItemLoaded && !isItemLoaded(index)) {
        return (
            <div style={style} className="rt-tr-group">
                <div className={`rt-tr -${index % 2 ? 'even' : 'odd'}`}>
                    <div className="rt-td rt-full">Loading</div>
                </div>
            </div>
        );
    }

    prepareRow?.(row);
    const {
        key,
        children: rowChildren,
        ...rowProps
    } = row?.getRowProps?.({
        ...(getRowProps?.(row) || {}),
    }) ?? {};

    return (
        <div className="rt-tr-group" key={row.id || key} {...containerProps}>
            <div style={style}>
                <div
                    ref={outerRef}
                    className={`rt-tr -${index % 2 ? 'even' : 'odd'}   ${
                        row?.isSelected ? 'rt-rt-selected' : ''
                    }`}
                    {...rowProps}
                >
                    {children ? (
                        <div className="rt-td">{children}</div>
                    ) : (
                        row.cells.map(cell => (
                            <div
                                className="rt-td"
                                key={cell.getCellProps().key}
                                {...cell.getCellProps()}
                                css={[
                                    cell.column.css,
                                    css`
                                        word-break: break-word;
                                    `,
                                    cell.column.style,
                                ]}
                            >
                                <div className="rt-td-content">
                                    {cellRender(cell)}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
            {rowChildren}
        </div>
    );
};

TableBodyRow.propTypes = {
    row: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.object,
    style: PropTypes.object,
    prepareRow: PropTypes.func,
    isItemLoaded: PropTypes.func,
    getRowProps: PropTypes.func,
    outerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    containerProps: PropTypes.object,
    cellRender: PropTypes.func,
};

export default TableBodyRow;
