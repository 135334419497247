import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose, branch } from 'recompose';
import Creatable from 'react-select/creatable';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { getSelectStyles } from './styled-components';
import {
    withInputValidation,
    withFinalFormField,
    ErrorWrapper,
    withLabel,
    withValidationWrapper,
} from '../../../../utils/hoc';
import { useTranslate } from '@mspecs/shared-utils';

const Container = styled(Flex)``;

const CreateTagSelect = props => {
    const {
        disabled,
        errorMessage,
        hideRequiredHint,
        invalid,
        placeholder,
        onChange: _onChange,
        required,
        value,
        createOption,
        ...restProps
    } = props;

    const [inputValue, setInputValue] = useState('');
    const { t } = useTranslate();

    const handleChange = value => {
        _onChange(value);
    };

    const handleInputChange = inputValue => {
        setInputValue(inputValue);
    };

    const handleKeyDown = event => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                handleChange([...value, createOption(inputValue)]);
                setInputValue('');
                event.preventDefault();
        }
    };

    const styles = getSelectStyles();
    return (
        <Container width={1}>
            <ErrorWrapper
                required={required}
                hideRequiredHint={hideRequiredHint}
                errorMessage={errorMessage}
                invalid={invalid}
                select
            >
                <Creatable
                    components={{
                        DropdownIndicator: null,
                    }}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    isClearable
                    isMulti
                    isDisabled={disabled}
                    onChange={handleChange}
                    menuIsOpen={false}
                    placeholder={t(placeholder)}
                    styles={{
                        ...styles,
                        control: (provided, props) => ({
                            ...styles.control(provided, props),
                            height: 'auto',
                            minHeight: '34px',
                        }),
                        multiValue: null,
                        multiValueLabel: null,
                        multiValueRemove: null,
                        valueContainer: null,
                    }}
                    value={value}
                    {...restProps}
                />
            </ErrorWrapper>
        </Container>
    );
};

CreateTagSelect.propTypes = {
    defaultLabel: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    getOptionValue: PropTypes.func,
    hideRequiredHint: PropTypes.bool,
    invalid: PropTypes.bool,
    sort: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.object,
            PropTypes.shape({
                value: PropTypes.any.isRequired,
                label: PropTypes.any.isRequired,
            }),
        ])
    ),
    required: PropTypes.bool,
    t: PropTypes.func,
};

CreateTagSelect.defaultProps = {
    defaultValue: null,
    defaultLabel: 'CHOOSE_OR_ADD',
    disabled: false,
    getOptionValue: option => option.value,
    sort: true,
    t: x => x,
    createOption: label => ({
        label,
        value: label,
    }),
};

export default compose(
    withValidationWrapper,
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    ),
    withLabel
)(CreateTagSelect);
