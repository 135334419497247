import React, { forwardRef, useRef, useEffect, useState } from 'react';
import { compose, branch } from 'recompose';
import PhoneInput, {
    isValidPhoneNumber,
    getCountryCallingCode,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
    withInputValidation,
    withFinalFormField,
    withErrorWrapper,
} from '../../../utils/hoc';
import { withValidationWrapper } from '../../../utils/hoc/validation-wrapper-component';
import CountrySelectWithFlag from './country-select-with-flag-component';
import { Input } from './input-field-component.styles';
import { Label } from './input-field-component';

const StyledPhone = styled(PhoneInput, {
    shouldForwardProp: prop =>
        !['invalid', 'inputWidth', 'useDBTranslation'].includes(prop),
})`
    width: 100%;

    .PhoneInputInput,
    .PhoneInputPhone {
        border-color: ${({ invalid, theme }) =>
            invalid && theme.colors.errorColor};
        .field-saved & {
            border-color: ${({ theme }) => theme.colors.successColor};
        }
        max-width: 100%;
        background: ${({ theme }) => theme.colors.bgPrimary};
        padding-left: 75px;
        &[disabled],
        &[readonly] {
            background-color: ${({ theme }) => theme.colors.bgSecondary};
        }
    }
`;

const validatePhoneNumber = value => {
    if (!value || !isValidPhoneNumber(value)) {
        return 'VALIDATION_ERROR_MOBILE_PHONE';
    }
};

const EnhancedPhoneInput = compose(
    withValidationWrapper,
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    ),
    withErrorWrapper
)(StyledPhone);

const TelephoneInput = props => {
    const {
        language,
        autoComplete,
        invalid,
        label,
        required,
        useDBTranslation,
        disabled,
        readOnly,
        ...restProps
    } = props;

    const [phoneCountryCode, phoneCountryCodeSetter] = useState(language);

    return (
        <>
            {label && (
                <Label
                    label={label}
                    required={required}
                    useDBTranslation={useDBTranslation}
                    disabled={disabled || readOnly}
                />
            )}
            <EnhancedPhoneInput
                autoComplete={autoComplete || 'tel'}
                defaultCountry={language}
                name="phoneNumber"
                errorMessage="VALIDATION_ERROR_MOBILE_PHONE"
                validationFun={validatePhoneNumber}
                countrySelectComponent={forwardRef((props, ref) => (
                    <CountrySelectWithFlag
                        {...props}
                        ref={ref}
                        disabled={disabled}
                    />
                ))}
                inputComponent={ForwardInput}
                isValid={!invalid}
                disabled={disabled}
                readOnly={readOnly}
                language={phoneCountryCode}
                onCountryChange={c => phoneCountryCodeSetter(c)}
                {...restProps}
            />
        </>
    );
};

const ForwardInput = forwardRef((props, ref) => {
    const { language, value, onChange, disabled, readOnly, ...restProps } =
        props;

    const inputRef = useRef(null);

    const handleChange = e => {
        if (onChange) {
            onChange(e.target.value);
        }
    };

    const displayValue =
        disabled && language ? `+${getCountryCallingCode(language)}` : value;

    return (
        <Input
            {...restProps}
            ref={node => {
                inputRef.current = node;
                if (typeof ref === 'function') ref(node);
                else if (ref) ref.current = node;
            }}
            value={displayValue}
            onChange={handleChange}
            disabled={disabled}
            readOnly={readOnly}
        />
    );
});

ForwardInput.propTypes = {
    language: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
};

export default TelephoneInput;

TelephoneInput.propTypes = {
    autoComplete: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    language: PropTypes.string,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    required: PropTypes.bool,
    useDBTranslation: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
};

TelephoneInput.defaultProps = {
    onBlur: x => x,
    onChange: x => x,
    language: 'SE',
};
