import { ac } from '../../utils/redux-utils';
import { legacyApi } from '@mspecs/shared-utils';

export const GET_NUMBER_OF_FLAGGED_GDPR_CONTACTS_SUCCESS =
    'GET_NUMBER_OF_FLAGGED_GDPR_CONTACTS';
export const GET_COMMUNICATE_COMMUNICATIONS_SUCCESS =
    'GET_COMMUNICATE_COMMUNICATIONS_SUCCESS';
export const GET_COMMUNICATE_COMMUNICATION_SUCCESS =
    'GET_COMMUNICATE_COMMUNICATION_SUCCESS';
export const POST_COMMUNICATE_SMS_SUCCESS = 'POST_COMMUNICATE_SMS_SUCCESS';
export const TOGGLE_GDPR_MODULE = 'TOGGLE_GDPR_MODULE';

// TODO: DRY this and its copy in email-actions.js
function getUserId(getState) {
    const state = getState();
    if (state && state.session) {
        return state.session.id;
    }
    return null;
}

function successGetCommunications(communications) {
    return ac(GET_COMMUNICATE_COMMUNICATIONS_SUCCESS, communications);
}

function successGetNrOfFlaggedContacts(data) {
    return ac(GET_NUMBER_OF_FLAGGED_GDPR_CONTACTS_SUCCESS, data);
}

export function toggleGdprCommunicationsModule() {
    return ac(TOGGLE_GDPR_MODULE);
}

// sendSmsToFlaggedContacts
export function sendSmsToFlaggedContacts(data) {
    return async () => {
        await legacyApi('/v2/gdpr/sendSmsToFlaggedContacts').put(data);
    };
}

export function exportFlaggedContactsEmails(communicationOrder) {
    return () =>
        legacyApi('/v2/gdpr/exportFlaggedContactsEmails')
            .saveResponseAsFile('emails.xls')
            .put({ communicationOrder });
}

export function printFlaggedContactLabels(communicationOrder) {
    return () =>
        legacyApi('/v2/gdpr/printLetterLabels')
            .saveResponseAsFile('labels.pdf')
            .put({ communicationOrder });
}

export function getNumberOfFlaggedGdprContacts() {
    return async dispatch => {
        /* eslint-disable no-unused-vars */
        const data = await legacyApi('/v2/gdpr/numberOfFlaggedContacts').get();
        dispatch(successGetNrOfFlaggedContacts(data));
    };
}

export function requestGetCommunications(perPage, offset) {
    return (dispatch, getState) => {
        const query = `createdByUserId='${getUserId(getState)}'`;
        const url = encodeURI(
            `/communications?q=${query}&sort=-updatedDate&limit=${perPage}&offset=${offset}`
        );
        const api = () => legacyApi(url);
        api()
            .get()
            .then(data => {
                dispatch(successGetCommunications(data));
            });
    };
}

function successGetCommunication(communication) {
    return ac(GET_COMMUNICATE_COMMUNICATION_SUCCESS, communication);
}

export function requestGetCommunication(communicationId) {
    const url = encodeURI(`/v2/communicate/${communicationId}`);
    const api = () => legacyApi(url);

    return dispatch =>
        api()
            .get()
            .then(data => {
                dispatch(successGetCommunication(data));
            });
}

export function requestPostCommunicateCommunication(contactIds) {
    const api = () => legacyApi('/v2/communicate');
    return (dispatch, getState) =>
        api()
            .post({ createdByUserId: getUserId(getState), contactIds })
            .then(data => {
                dispatch(successGetCommunication(data));
            });
}

export function requestPutCommunication(communicationId, fields) {
    const api = () => legacyApi(`/v2/communicate/${communicationId}`);
    return dispatch =>
        api()
            .put(fields)
            .then(data => {
                dispatch(successGetCommunication(data));
            });
}

export function requestPutCommunicateMethods(communicationId, orderedMethods) {
    const api = () => legacyApi(`/v2/communicate/${communicationId}/methods`);
    return dispatch =>
        api()
            .put({ orderedMethods })
            .then(data => {
                dispatch(successGetCommunication(data));
            });
}

function requestGetAndSaveOutboxInfo(communicationId, smses) {
    // TODO: error handling if there are no smses in the response.
    const sms = smses[0];

    // sms id comes in the following format:
    // "messageid": "mspx_2|MDAwMnwwMDAwMDAwMDA1M3w5Mw..",
    const rawId = sms.messageid;
    const smsId = rawId.substring(rawId.indexOf('|') + 1);

    const api = () => legacyApi(`/outgoingSms/${smsId}`);

    return dispatch =>
        api()
            .get()
            .then(data => {
                dispatch(
                    requestPutCommunication(communicationId, {
                        smsOutboxId: data.smsOutboxId,
                    })
                );
            });
}

// TODO: support communicationTokenId
// TODO: if organization unit id is available it should be
// added to the end of the request url,
// e.g. /sms/createAndSendSms/MDAwMnwwMDAwMDAwMDAwMXw0NA
export function requestPostCommunicateSms(communicationId, sms) {
    const api = () => legacyApi('/sms/createAndSendSms');

    return dispatch =>
        api()
            .post(sms)
            .then(data =>
                dispatch(requestGetAndSaveOutboxInfo(communicationId, data))
            );
}
