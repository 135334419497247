import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { delay } from '@mspecs/shared-utils';
import { sendResetPassword } from '~/utils/services/auth-service';
import Login from '~/features/login/login-component';
import ForgotPassword from '~/features/login/forgot-password-component';
import { PageTitle } from '~/utils/use-set-page-title-hook';

const LoginPage = () => {
    const location = useLocation();
    const [forgotPassword, setForgotPassword] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailSentFail, setEmailSentFail] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');

    const resetPassword = async ({ email }) => {
        try {
            await sendResetPassword(email);
            setEmailSent(true);
            setForgotPassword(false);
            setEmailAddress(email);
            await delay(5000);
            setEmailSent(false);
        } catch (e) {
            setEmailSentFail(true);
            await delay(5000);
            setEmailSentFail(false);
        }
    };

    const redirectUrl = useMemo(() => {
        const qParams = new URLSearchParams(location.search);
        const redirectUrl = qParams.get('redirect') || window.sessionStorage.getItem('currentLocation');
        window.sessionStorage.removeItem('currentLocation');
        return redirectUrl;
    }, [location.search]);

    return forgotPassword ? (
        <>
            <PageTitle title="FORGOT_PASSWORD" />
            <ForgotPassword
                resetPassword={resetPassword}
                toggleForgotPassword={() => setForgotPassword(!forgotPassword)}
                emailSentFail={emailSentFail}
            />
        </>
    ) : (
        <Login
            isSent={emailSent}
            toggleForgotPassword={() => setForgotPassword(!forgotPassword)}
            emailAddress={emailAddress}
            redirectUrl={redirectUrl}
        />
    );
};

export default LoginPage;
