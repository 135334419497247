import React from 'react';
import PropTypes from 'prop-types';
import { InputField } from '.';
import { useTranslate, formatCoordinateToDegrees } from '@mspecs/shared-utils';

const CoordinateInput = ({ coordinateType, displayValue, ...props }) => {
    const { t } = useTranslate();

    return (
        <>
            <InputField
                style={{ marginBottom: 0, marginRight: '5px' }}
                {...props}
                placeholder={t(coordinateType)}
            />
            <InputField
                disabled
                value={formatCoordinateToDegrees(displayValue, coordinateType)}
                style={{ marginLeft: '5px' }}
            />
        </>
    );
};
CoordinateInput.propTypes = {
    displayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    coordinateType: PropTypes.oneOf(['LATITUDES', 'LONGITUDES']).isRequired,
};

export default CoordinateInput;
