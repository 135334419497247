import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import {
    Border,
    LoginContainer,
    FormContent,
    LinkFooter,
} from './styled-components';
import {
    Button,
    BUTTON_TYPES,
    ButtonToolbar,
    PasswordInput,
} from '@mspecs/ui-components';
import { translate } from '@mspecs/shared-utils';
import { displayError, displaySuccess } from '../notifications/services';

const ChangePassword = ({ handleChangePassword, passwordChanged, t }) => {
    const fieldProps = {
        required: true,
        hideRequiredHint: true,
        isFormField: true,
        stackedLabel: true,
    };

    useEffect(() => {
        if (passwordChanged === 'error') {
            displayError('CHANGE_PASSWORD_ERROR', 5000);
            return;
        }

        if (passwordChanged === 'success') {
            displaySuccess('CHANGE_PASSWORD_SUCCESS', 5000);
            return;
        }
    });

    return (
        <LoginContainer headline={t('CHANGE_PASSWORD_HEADING')}>
            <Border />
            <Form
                onSubmit={handleChangePassword}
                validate={values => {
                    const errors = {};
                    if (values.confirmedPassword !== values.password) {
                        errors.confirmedPassword =
                            'CHANGE_PASSWORD_ERROR_NOT_MATCHING';
                    }
                    return errors;
                }}
                render={({ handleSubmit, submitting, invalid }) => (
                    <FormContent onSubmit={handleSubmit}>
                        <PasswordInput
                            name="password"
                            label="CHANGE_PASSWORD_NEW_PASSWORD"
                            minCharacters
                            {...fieldProps}
                        />
                        <PasswordInput
                            name="confirmedPassword"
                            label="CHANGE_PASSWORD_CONFIRM_PASSWORD"
                            {...fieldProps}
                        />
                        <ButtonToolbar>
                            <Button
                                label="CHANGE_PASSWORD_BUTTON_LABEL"
                                type="submit"
                                buttonType={BUTTON_TYPES.PRIMARY}
                                disabled={invalid || submitting}
                                large
                            />
                        </ButtonToolbar>
                    </FormContent>
                )}
            />
            <LinkFooter contactSupport />
        </LoginContainer>
    );
};

ChangePassword.propTypes = {
    handleChangePassword: PropTypes.func,
    passwordChanged: PropTypes.string,
    t: PropTypes.func,
};

export default translate(ChangePassword);
